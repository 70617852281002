import React, { Fragment } from 'react';
import Layout from '../../components/LayoutFunnel';
import cx from 'classnames';
import Styles from '../../assets/styles/funnel.module.css'
import './choose-postcode.css'
import SecuredappDesktop from "../../assets/images/securedapp-section-img.png";
import SecuredappMobile from "../../assets/images/securedapp-section-mob-img.png";
import flagIcon from '../../assets/images/flagIcon.png'
import tickImage from '../../assets/images/tick-img.png'
import hashchingMagnifyingGlass from '../../assets/images/hashchingMagnifyingGlass.png'
import logo from '../../assets/images/logo.png'
import validator from 'validator';
import DealList from "../../components/deals/dealList";
import Axios from "axios";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DealDetailSection from '../../components/deals/dealDetailSection'
import classes from '../../assets/styles/autoSearchBroker.module.css';
import BrokerListing from '../../components/brokers/brokerListing'
import BrokerDetailSection from '../../components/brokers/brokerDetailSection';
//import BookAppointmentSidebar from './bookAppointmentSidebar';
import DisplaySingleBroker from './DisplaySingleBroker';
import FunnelSaveYourProgress from './FunnelSaveYourProgress';
import Cookies from 'universal-cookie';
import { navigate } from "gatsby"
import BorrowingCalculator from "../../components/borrowing-calculator";
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { graphql } from 'gatsby';
import { log, oWindow, host, removeCookie } from '../../helpers/commons'
import ReactTooltip from 'react-tooltip'
import questionMark from "../../assets/images/question-mark.png";
import allKenticoCloudItemFieldMinimumLoanAmount from "../../../data/kentico/allKenticoCloudItemFieldMinimumLoanAmount";
import allKenticoCloudItemLeadSCount from "../../../data/kentico/allKenticoCloudItemLeadSCount";

library.add(faCheck)


 const cookies = new Cookies();
    cookies.remove('rates_comparison_interest_rate');
    cookies.remove('rates_interest_type');
    cookies.remove('rates_interest_rate');

    //const url = host +  `/gatsby-api/get-lowest-deal`;
    const url = host +  '/gatsby-api/get-lowest-deal';
    
    cookies.set('consentcookie', 1, { path: '/' });
    

    Axios.get(url, {

    }).catch(function (error) {
       console.error(error);
    }).then((response) => {
//       console.log(response.data);
    
        cookies.set('rates_comparison_interest_rate',response.data.comparison_interest_rate);
        cookies.set('rates_interest_type',response.data.interest_type);
        cookies.set('rates_interest_rate',response.data.interest_rate);
       
    })
 
const rates_comparison_interest_rate = cookies.get('rates_comparison_interest_rate');
const rates_interest_type = cookies.get('rates_interest_type');
const rates_interest_rate = cookies.get('rates_interest_rate');
class FunnelApp extends React.Component {

    constructor(props) {

        super(props);
        // this.loanAmount = props.data.allKenticoCloudItemFieldMinimumLoanAmount.edges[0].node || 50000
        // this.borrowerStats = props.data.allKenticoCloudItemLeadSCount.edges[0].node || '46,407'
        this.loanAmount = allKenticoCloudItemFieldMinimumLoanAmount.edges[0].node || 50000
        this.borrowerStats = allKenticoCloudItemLeadSCount.edges[0].node || '46,407'
        this.state = {
            steps: {
                current: 'get-started'
            },
            funnelData: {
                broker: null,
                deal: null,
                lead_type: null,
                postcode: null,
                suburb: null,
                state: null
            },
            utmTags: {},
            referrerKey: '',
            postcodeId: '',
            flowStartedFrom: null,
            saveProgressEmail: null,
            flow: null, // to check for lead or broker flow
            prevStep: null,
            brokerSearchArea: '',
            stepsFollowed: [],
            isNoDepositFunnel: false,
            fromNoDepositLandingPage: false,
            FunnelStepsDeals: [
                {
                    className: 'active',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                // {
                //   className: '',
                //   labelText: 'Your rates'
                //},
                // {
                //     className: '',
                //     labelText: 'Your broker'
                // },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ],
            FunnelStepsBrokers: [
                {
                    className: 'active',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                {
                    className: '',
                    labelText: 'Your broker'
                },
                // {
                //     className: '',
                //     labelText: 'Your rates'
                // },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ],
            FunnelStepsDirectBrokers: [
                {
                    className: '',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ],
            FunnelStepsDirectDeals: [
                {
                    className: 'active',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                // {
                //     className: '',
                //     labelText: 'Your broker'
                // },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ],
            FunnelStepsDirectBoth: [
                {
                    className: '',
                    labelText: 'Your enquiry'
                },
                {
                    className: '',
                    labelText: 'The property'
                },
                {
                    className: '',
                    labelText: 'Appointment'
                }
            ]
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleSaveProgress = this.handleSaveProgress.bind(this);
        this.dealClickHandler = this.dealClickHandler.bind(this);
        this.backBtnHandler = this.backBtnHandler.bind(this);
        this.handleBrokerClick = this.handleBrokerClick.bind(this);
        this.skipBtnHandler = this.skipBtnHandler.bind(this);
        this.getSteps = this.getSteps.bind(this);
        this.handleBookAppointmentData = this.handleBookAppointmentData.bind(this);
        this.handleBrokerContactMe = this.handleBrokerContactMe.bind(this);
        this.handleCalculatorClick = this.handleCalculatorClick.bind(this);
        this.handleSidebarClick = this.handleSidebarClick.bind(this);
        this.handleSaveYourProgress = this.handleSaveYourProgress.bind(this);
        this.handleChangeRoute = this.handleChangeRoute.bind(this);
        this.onRouteUpdated = this.onRouteUpdated.bind(this);
        this.continueChooseBrokerHandler = this.continueChooseBrokerHandler.bind(this);
        this.preferSomeoneElseHandler = this.preferSomeoneElseHandler.bind(this);
        this.postcodeUpdateHandler = this.postcodeUpdateHandler.bind(this)
        this.setFunnelFlow = this.setFunnelFlow.bind(this);
        this.dealSkipClickHandler = this.dealSkipClickHandler.bind(this);
        this.cookieSet('flow', 'get-started');
        this.setFunnelFlow();
    }


    dealSkipClickHandler() {
        log("deal skip handler called..");
        this.skipBtnHandler('appointment')
    }

    postcodeUpdateHandler(postcode, data, searchArea = '') {

        log(arguments, 'postcode arguments')

        log(postcode, "postcode change triggered");

        let updateItem = {};

        let postcodeId = this.state.postcodeId;

        if (!!searchArea) {
            this.setState({
                brokerSearchArea: searchArea
            })
        }

        if (!!postcode && typeof postcode == 'object') {
            updateItem.postcode = postcode.postcode
            updateItem.suburb = postcode.locality
            updateItem.state = postcode.statecode
            postcodeId = postcode.id

            this.setState({
                funnelData: {
                    ...this.state.funnelData, ...updateItem,
                },
                postcodeId: postcodeId,
                brokerSearchArea: searchArea
            })
        }
    }

    continueChooseBrokerHandler(broker, postcode = '') {
        log(broker);
        let updateItem = {};
        updateItem.broker = broker;
        let postcodeId = this.state.postcodeId;

        if (!!postcode && typeof postcode == 'object') {
            updateItem.postcode = postcode[0]
            updateItem.suburb = postcode[1]
            updateItem.state = postcode[2]
            postcodeId = postcode[3]
        }

        this.setState({
            funnelData: {
                ...this.state.funnelData, ...updateItem,
            },
            broker: broker,
            postcodeId: postcodeId
        }, () => {
            this.handleClick('appointment');
        })
    }

    preferSomeoneElseHandler(postcode, broker) {
        let oPostcode = [];
        if (postcode) {
            this.setState({
                broker: broker,
                brokerSearchArea: ''
            }, () => {
                this.handleClick('mortgage-broker-finder', postcode);
            })
        }

        if (!postcode) {
            if (this.state.funnelData.postcode
                && this.state.funnelData.suburb && this.state.funnelData.state && this.state.postcodeId) {
                oPostcode[0] = this.state.funnelData.postcode;
                oPostcode[1] = this.state.funnelData.suburb;
                oPostcode[2] = this.state.funnelData.state;
                oPostcode[3] = this.state.postcodeId;
                this.setState({
                    broker: broker
                }, () => {
                    this.handleClick('mortgage-broker-finder', oPostcode);
                })
            }
        }
    }


    onRouteUpdated(route) {
        log(route, "on route updated called..");
    }

    setFunnelFlow() {
        let broker = this.cookieGet('broker');
        let deal = this.cookieGet('deal');
        log(deal);
        log(broker);
        log("setfunnel called..");

        if (!!deal && broker == undefined) {
            this.cookieSet('flow', 'deals');
        }

        if (!!broker && deal == undefined) {
            this.cookieSet('flow', 'brokers');
        }

        if (!!broker && !!deal) {
            this.cookieSet('flow', 'both');
        }
    }

    getSteps() {
        let flow = this.cookieGet('flow');
        log(flow, "cookie flow")
        let steps = this.state.FunnelSteps;
        let stateFlow = this.state.flow;
        log(stateFlow, "state flow")

        if (flow == 'get-started' && stateFlow == 'deals') {
            return this.state.FunnelStepsDeals;
        } else if (flow == 'get-started' && stateFlow == 'brokers') {
            return this.state.FunnelStepsBrokers;
        }

        if (flow == 'brokers') {

            if (flow == 'brokers' && (stateFlow == 'brokers' || stateFlow == null)) {
                return this.state.FunnelStepsDirectBrokers;
            }

            if (flow == 'brokers' && stateFlow == 'deals') {
                return this.state.FunnelStepsDirectBrokers;
            }

            if (flow == 'brokers' && stateFlow == 'both') {
                return this.state.FunnelStepsDirectBrokers;
            }
        }

        if (flow == 'deals') {

            if (flow == 'deals' && (stateFlow == 'deals' || stateFlow == null)) {
                return this.state.FunnelStepsDirectDeals;
            }

            if (flow == 'deals' && stateFlow == 'brokers') {
                return this.state.FunnelStepsDirectDeals;
            }

            if (flow == 'deals' && stateFlow == 'both') {
                return this.state.FunnelStepsDirectDeals;
            }
        }

        if (flow == 'both') {
            return this.state.FunnelStepsDirectBoth;
        }

        return this.state.FunnelStepsDeals;
    }

    cookieSet(variable, value = '') {
        let cookies = new Cookies();
        cookies.set(variable, value, { path: '/' });
        return cookies.get(variable)
    }

    cookieGet(variable) {
        let cookies = new Cookies();
        return cookies.get(variable)
    }

  

    handleSaveYourProgress(email, name = '') {
        if (email) {
            this.cookieSet('SaveEmail', email)
        }

        if (name) {
            this.cookieSet('SaveName', name)
        }

        console.log("handle deal listing funnel function===?");

        this.handleClick('appointment');

        return false;

        // if (this.state.flow == 'brokers') {
        //     this.handleClick('appointment');
        // } else {
        //     this.handleClick('appointment'); /*hide broker selection in default flow*/
        //     //this.handleClick('choose-postcode'); 
        // }
    }

    backBtnHandler() {

        if (typeof window != 'undefined') {
            window.history.back();
        }
        return false;
    }

    handleCalculatorClick() {
        log("calculator handler is clicked");
        this.handleClick('property-info', this.state.flow);
    }

    handleSidebarClick() {
        log("sidebar handler called is clicked");
        let gotToPrevStep = (this.state.flow) ? 'property-info' : "property-info";
        this.handleClick('calculator', '', gotToPrevStep);
    }

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
const token = query.get('t')


console.log(token); 
if(token != null && token != undefined && token != '')
{
    document.getElementById(token).click();
}
        // AddClassInBody();
        let params = {}, queries, temp, i, l, queryString;
        queryString = this.props.location.search;
        if (queryString.indexOf('?') != -1) {
            queryString = decodeURIComponent(queryString.substring(queryString.indexOf('?') + 1));
            queries = queryString.split("&");
            for (i = 0, l = queries.length; i < l; i++) {
                temp = queries[i].split('=');
                if (temp.length > 2) {
                    params[temp[0]] = `${temp[1]}=`;
                } else {
                    params[temp[0]] = temp[1];
                }
            }
        }

        if (Object.keys(params).length != 0) {
            this.cookieSet('referrerId', params.id);
            if (params.utm_campaign || params.utm_medium || params.utm_source || params.utm_term) {
                this.cookieSet('utm_campaign', params.utm_campaign);
                this.cookieSet('utm_medium', params.utm_medium);
                this.cookieSet('utm_source', params.utm_source);
                this.cookieSet('utm_term', params.utm_term);

            }
        }

        if (this.cookieGet('utm_campaign')) {
            params['utm_campaign'] = this.cookieGet('utm_campaign');
        }
        if (this.cookieGet('utm_medium')) {
            params['utm_medium'] = this.cookieGet('utm_medium');
        }
        if (this.cookieGet('utm_source')) {
            params['utm_source'] = this.cookieGet('utm_source');
        }
        if (this.cookieGet('utm_term')) {
            params['utm_term'] = this.cookieGet('utm_term');
        }
        if (this.cookieGet('referrerId')) {
            params['referrerId'] = this.cookieGet('referrerId');
        }
        if (this.props.location.href) {
            let pageSource = this.props.location.origin + '/loan-enquiry/';
            let isNoDepositFunnel = false;
            let fromNoDepositLandingPage = false;
            if (typeof (Storage) !== "undefined" && sessionStorage.landingData) {
                let landingData = sessionStorage.landingData
                if (!!landingData) {
                    landingData = JSON.parse(landingData);
                    if (!!landingData.pageSource) {
                        pageSource = landingData.pageSource;
                    }

                    if (landingData.flow == 'specific') {
                        isNoDepositFunnel = true;
                    }
                    fromNoDepositLandingPage = true; //user is from No deposit Landing page
                    log(landingData, "landing page data");
                }
            }

            this.setState({
                pageSource: pageSource,
                isNoDepositFunnel: isNoDepositFunnel,
                fromNoDepositLandingPage: fromNoDepositLandingPage
            });
        }
        if (this.cookieGet('postcode_id')) {
            this.setState({
                postcodeId: this.cookieGet('postcode_id')
            });
        }
        if (Object.keys(params).length != 0) {
            if (params.referrerId) {
                this.setState({
                    referrerKey: params.referrerId
                });
            }
            if (params.utm_campaign || params.utm_medium || params.utm_source || params.utm_term) {
                this.setState({
                    utmTags: params
                });
            }
        }

      
    }

    handleSaveProgress(event) {

        if (event.target.id == 'hash-btn-skip-progress') {
            return false;
        }

        // if (document.getElementById('progress-email')) {
        //     let email = document.getElementById('progress-email').value
        //     // log(validator.isEmail(email));
        //     if (!(!!email)) {
        //         if (document.getElementById('progress-email-error')) {
        //             document.getElementById('progress-email-error').innerHTML = 'Please enter your email.'
        //             document.getElementById('progress-email-error').style.display = 'block';
        //         }
        //     } else if (!validator.isEmail(email)) {
        //         if (document.getElementById('progress-email-error')) {
        //             document.getElementById('progress-email-error').innerHTML = 'Please enter valid email.'
        //             document.getElementById('progress-email-error').style.display = 'block';
        //         }
        //     } else {
        //         if (document.getElementById('progress-email-error')) {
        //             document.getElementById('progress-email-error').style.display = 'none';
        //             this.setState({
        //                 saveProgressEmail: email
        //             })
        //         }
        //     }
        // }
        // log('save progress clicked..');
    }

    validateSteps(step, data = null) {
        if (step) {
            let state = this.state;
            let currentStep = state.steps.current;
            let funnelData = state.funnelData;
            console.log(data, '====> route data');
            console.log(step, '====> route ');
            let flow = this.cookieGet('flow');

            if (step == 'new-loan' || step == 'refinance') {


                if (flow == 'get-started' && state.isNoDepositFunnel == true) {
                    this.setState({
                        funnelData: { ...funnelData, lead_type: step },
                        flow: 'deals'
                    })
                    return 'property-info';
                }

                if (flow == 'deals') {
                    this.setState({
                        funnelData: { ...funnelData, lead_type: step },
                        flow: 'deals'
                    })
                    return 'property-info';
                }

                if (flow == 'brokers') {
                    this.setState({
                        funnelData: { ...funnelData, lead_type: step },
                        flow: 'brokers'
                    })
                    return 'property-info';
                }

                if (flow == 'both') {
                    this.setState({
                        funnelData: { ...funnelData, lead_type: step },
                        flow: 'both'
                    })
                    return 'property-info';
                }

                if (flow == 'get-started') {
                    this.setState({
                        funnelData: { ...funnelData, lead_type: step },
                        flow: 'deals'
                    })
                    return 'property-info';
                }

                return step;
            }

            if (step == 'property-info') {
                log(data, "property flow");
                if (funnelData.lead_type) { // we must have lead type at this step
                    this.setState({
                        flow: data
                    })
                    return step;
                }
            }

            if (step == 'property-info-brokers') {
                if (funnelData.lead_type) { // we must have lead type at this step
                    this.setState({
                        flow: 'brokers'
                    })
                    return step;
                }
            }

            if (step == 'property-info-deals') {
                if (funnelData.lead_type) { // we must have lead type at this step
                    this.setState({
                        flow: 'deals'
                    })
                    return step;
                }
            }

            if (step == 'choose-postcode') {

                if (currentStep == 'deal-info') {

                    let broker = this.cookieGet('broker');

                    let postcode = this.cookieGet('postcode');

                    if (!!broker) {

                        this.setState({
                            funnelData: { ...funnelData, broker: Number(broker), postcode: Number(postcode) }
                        })

                        return 'appointment'; // skip the broker selection process
                    }
                }

                if (!!funnelData.lead_type && !!state.flow) {
                    let aboutProperty = data;
                    if (currentStep == 'property-info') {
                        this.setState({
                            funnelData: { ...funnelData, aboutProperty }
                        })
                    }

                    let broker = this.cookieGet('broker');

                    let postcode = this.cookieGet('postcode');

                    let deal = this.cookieGet('deal');

                    if (!!broker) { // if broker is already selected 

                        let items = {};

                        items['broker'] = Number(broker); // save the broker 

                        if (!!deal) { // if deal is selected save this as well
                            items['deal'] = Number(deal);
                        }

                        items['aboutProperty'] = aboutProperty;

                        this.setState({
                            funnelData: { ...funnelData, ...items }
                        })

                        if (!!deal) { // if deals is already selected
                            return 'appointment';
                        }
                        // for the latest changes if we are on brokers flow 
                        // don't show the deals listing direct go to appointment pages
                        if (this.state.flow == 'brokers') {
                            return 'appointment';
                        }

                        return 'appointment'
                    }

                    return step;
                }
            }

            if (step == 'deal-listing') {

                console.log("In the deal listing step");

                let aboutProperty = data;

                if (currentStep == 'property-info') {

                    let broker = this.cookieGet('broker');

                    let postcode = this.cookieGet('postcode');

                    let deal = this.cookieGet('deal');

                    let items = {};

                    if (!!deal) {

                        items['deal'] = Number(deal);

                        if (!!broker) {
                            items['broker'] = Number(broker);
                        }

                        items['aboutProperty'] = aboutProperty;

                        this.setState({
                            funnelData: { ...funnelData, ...items }
                        })

                        if (!!broker) {
                            return 'appointment';
                        }

                        return 'appointment'; /*hide broker selection in default flow*/
                        //return 'choose-postcode'; // skip the broker selection process
                    }
                }

                if (currentStep == 'broker-profile') {
                    this.setState({
                        funnelData: { ...funnelData, broker: data }
                    })
                }



                if (!!funnelData.lead_type && !!state.flow) {

                    if (currentStep == 'property-info') {
                        this.setState({
                            funnelData: { ...funnelData, aboutProperty }
                        })
                    }

                    if (!!data && data.personalLoan == 'Yes') {
                        return 'appointment';
                    }
                    else {
                        return step;
                    }


                }
            }

            if (step == 'deal-info') {
                log('====> deal info');

                this.setState({
                    deal: data
                })
                return step;
            }

            if (step == 'mortgage-broker-finder') {
                let searchArea = this.state.searchArea;
                if (this.state.flow == 'brokers') {
                    searchArea = ''
                }
                log(data);
                this.setState({
                    funnelData: { ...funnelData, postcode: data[0], suburb: data[1], state: data[2] },
                    postcodeId: data[3],
                    brokerSearchArea: searchArea
                })
                log('====> broker finder step');
                return step;
            }

            if (step == 'broker-profile') {
                log('In broker details')
                this.setState({
                    broker: data
                })
                log('====> broker details step');
                return step;
            }

            if (step == 'save-your-progress') {

                log('====> save your progress step');
                log(data);
                console.log(currentStep, "===================>>>");

                if (currentStep == 'property-info' || 'save-your-progress') {
                    const aboutProperty = data;
                    aboutProperty.time = new Date().toISOString();
                    console.log("about property===>>>", aboutProperty);
                    this.setState((state, props) => ({
                        funnelData: { ...this.state.funnelData, aboutProperty }
                    }))
                    return step;
                }

                if (!!data) {
                    this.setState({
                        funnelData: { ...funnelData, broker: data }
                    })
                }

                return step;
            }

            if (step == 'appointment') {
                let SavedEmail = this.cookieGet('SaveEmail');
                if (!!data) {
                    log(data, '=>broker selected');
                    this.setState({
                        funnelData: { ...funnelData, broker: data }
                    })
                }

                log('====> appointment step');
                return step;
            }

            if (step == 'thank-you') {
                let bookAppointment = data;
                this.setState({
                    funnelData: { ...funnelData, bookAppointment }
                })
                log('====> thank you step');
                return step;
            }

            if (step == 'dashboard') {
                log('====> dashboard step');
                return step;
            }

            if (step == 'calculator') {
                log('====> calculator step');
                return step;
            }
        }

        return false;
    }

    handleClick(nextStep, data = null, gotToPrevStep = '') {
        let nextStepReturned = this.validateSteps(nextStep, data);

        console.log('====>>> next route returned', nextStepReturned);
        console.log("=====> current state", this.state)
        if (nextStepReturned) {
            let steps = this.state.steps;
            let currentStep = this.state.steps.current;
            let prevSteps = this.state.stepsFollowed

            if (prevSteps.indexOf(currentStep) == -1) {
                if (currentStep == 'refinance' || currentStep == 'new-loan') {
                    prevSteps[1] = currentStep;
                } else if (currentStep == 'property-info') {
                    prevSteps[2] = currentStep;
                } else {
                    prevSteps.push(currentStep);
                }

            }

            if (prevSteps.indexOf(nextStepReturned) == -1) {
                if (nextStepReturned == 'refinance' || nextStepReturned == 'new-loan') {
                    prevSteps[1] = nextStepReturned;
                } else if (nextStepReturned == 'property-info') {
                    prevSteps[2] = nextStepReturned;
                } else {
                    prevSteps.push(nextStepReturned);
                }
            }

            this.setState({
                steps: { ...steps, current: nextStepReturned },
                prevStep: currentStep,
                stepsFollowed: prevSteps,
                gotToPrevStep: gotToPrevStep
            })

            this.handleChangeRoute(nextStepReturned)
        }
    }

    componentWillMount() {

        let urlParts = [];
        let slug = '';

        if (typeof document != 'undefined') {
            log(window.location.hash, "window hash");
            slug = window.location.hash.replace('#/', '');
        }


        let validSlugs = ['new-loan', 'refinance', 'calculator',
            'property-info-brokers', 'property-info-deals', 'deal-listing', 'deal-info',
            'choose-postcode', 'mortgage-broker-finder', 'broker-profile', 'save-your-progress',
            'appointment', 'thank-you', 'dashboard', 'property-info']

        if (!!slug) {

            if (validSlugs.indexOf(slug) == -1) {
                // window.location.hash = '#/' + step
                navigate('/loan-enquiry')
            }
            //if is not not first step
            if (this.cookieGet('funnelData') == undefined) {
                navigate('/loan-enquiry')
            }
        }

        if (!slug) {
            if (oWindow.funnelInProgress == 'undefined') {
                removeCookie('funnelData');
            }
            log("at the first step.");
        }

        log("component will mount called...")

        let cookeState = this.cookieGet('funnelData');

        if (!!cookeState && cookeState != undefined) {

            let stepsFollowed = cookeState.stepsFollowed;

            log(stepsFollowed);

            if (!!slug && stepsFollowed.indexOf(slug) == -1) {
                log("at the wrong place");
                navigate('/loan-enquiry')
            }

            if (!!slug && cookeState.steps.current != slug) {
                cookeState.steps.current = slug
            }
        }

        if (cookeState != undefined) {
            log('inside set state')
            this.setState(cookeState);
        } else {
            this.cookieSet('funnelData', this.state)
            log(this.state, 'will mount current state==>');
        }
    }

    handleChangeRoute(route) {

        let currentState = this.state;
        log(currentState, "current state in route change ===>")
        this.cookieSet('funnelData', currentState)
        oWindow.write("funnelInProgress", "true");
        // log(route, "===> called change route");

        if (typeof window != 'undefined') {
            window.location.hash = '#/' + route
        }
        // navigate('/loan-enquiry/#/' + route);
    }

    handleBrokerContactMe(broker) {

        log(broker);
        let deal = this.cookieGet('deal');
        let email = this.cookieGet('SaveEmail');
        let flow = this.state.flow;

        log(flow, "==> contact me flow => ")
        log(email, "==> contact me email")

        if (flow == "brokers") {

            if (deal != undefined) {
                this.handleClick('appointment', broker);
                return true;
            }

            if (email != undefined) {
                this.handleClick('appointment', broker);
            } else {
                this.handleClick('save-your-progress', broker);
            }
        }

        if (flow == 'deals') {
            this.handleClick('appointment', broker);
        }

    }

    handleBrokerClick(broker) {
        this.handleClick('broker-profile', broker.broker_id);
    }

    handleBookAppointmentData(data) {
        log('appointment handle is called..');
    }

    dealClickHandler(deal = null, from = '') {

        log("deal click handler called...")

        if (!!from && from == 'deal-info') {

            log(deal);

            let state = this.state;

            let updateObj = {};

            updateObj.deal = deal.deal_id;

            updateObj.broker = this.state.funnelData.broker;

            let broker = this.state.broker; //temp broker

            if (!!updateObj.broker && typeof (Storage) !== "undefined" && sessionStorage.dealBrokers) {
                let dealBrokers = sessionStorage.dealBrokers
                if (dealBrokers) {
                    dealBrokers = dealBrokers.split(",");
                    if (!(dealBrokers.indexOf(updateObj.broker.toString()) != -1)) {
                        updateObj.broker = ''; //clear broker because it is invalid at this time.
                        broker = '';
                    }
                }
            }

            this.setState({
                funnelData: { ...this.state.funnelData, ...updateObj },
                broker: broker
            }, () => {
                if (state.flow == 'brokers') {
                    this.handleClick('appointment');
                }

                if (state.flow == 'deals') {
                    let email = this.cookieGet('SaveEmail')

                    let broker = this.cookieGet('broker');

                    if (broker != undefined) {
                        this.handleClick('choose-postcode');
                        return true;
                    }

                    if (email != undefined) {
                        //this.handleClick('choose-postcode');
                        this.handleClick('appointment'); /*hide broker selection in default flow*/
                    } else {
                        this.handleClick('save-your-progress');
                    }
                }
            })



            return true;
        }
        log(deal);
        this.handleClick('deal-info', deal);
        log('deal handler called');
    }

    skipBtnHandler(step) {

        log('skip this step called...');
        log(step);
        let state = this.state;
        let cookieFlow = this.cookieGet('flow');
        let broker = this.cookieGet('broker');
        let deal = this.cookieGet('deal');
        log(' flow ==>' + state.flow)

        if (state.flow == 'brokers' && step == 'choose-postcode') {
            this.skipToStep('appointment');
        }

        if (state.flow == 'deals' && step == 'choose-postcode') {
            this.skipToStep('appointment');
        }

        if (state.flow == 'brokers' && step == 'save-your-progress') {
            this.skipToStep('appointment');
        }

        if (state.flow == 'deals' && step == 'save-your-progress') {
            //this.skipToStep('choose-postcode');
            this.skipToStep('appointment'); /*hide broker selection in default flow*/
        }

        if (state.flow == 'brokers' && (step == 'deal-listing' || step == 'deal-details')) {
            this.skipToStep('appointment');
        }



        if (state.flow == 'deals' && (step == 'deal-listing' || step == 'deal-details')) {
            if (broker != undefined) {
                this.skipToStep('appointment');
                return false;
            }
            //this.skipToStep('choose-postcode');
            this.skipToStep('appointment'); /*hide broker selection in default flow*/
        }

        if (state.flow == 'brokers' && (step == 'broker-profile' || step == 'mortgage-broker-finder')) {
            log(deal == 'undefined')
            this.skipToStep('appointment');
            // if (deal == undefined) {
            //     this.skipToStep('deal-listing');
            // } else {
            //     this.skipToStep('appointment');
            // }
        }

        if (state.flow == 'deals' && (step == 'broker-profile' || step == 'mortgage-broker-finder')) {
            this.skipToStep('appointment');
        }
    }

    skipToStep(step) {
        this.handleClick(step);
        if (typeof window != 'undefined') {
            window.location.hash = '#/' + step
        }
        // navigate('/loan-enquiry/#/' + step)
    }

    renderStep() {

        let AllProps = {
            handleChangeStep: this.handleClick,
            currentState: this.state,
            backBtnHandler: this.backBtnHandler,
            funnelData: this.state.funnelData,
            skipBtnHandler: this.skipBtnHandler,
            getSteps: this.getSteps,
            handleBookAppointmentData: this.handleBookAppointmentData,
            handleBrokerContactMe: this.handleBrokerContactMe,
            handleCalculatorClick: this.handleCalculatorClick,
            handleSidebarClick: this.handleSidebarClick,
            handleSaveYourProgress: this.handleSaveYourProgress
        };
        let currentState = this.state.steps.current;

        if (currentState == 'get-started') {
            return <LoanType {...AllProps} />
        }

        if (currentState == 'new-loan' || currentState == 'refinance') {
            return <NewLoan {...AllProps} />
        }

        if (currentState == 'calculator') {
            return <Calculator {...AllProps} />
        }

        if (currentState == 'property-info-brokers') {
            return <PropertyInfo {...AllProps} Minimumloanamount={this.loanAmount} />
        }

        if (currentState == 'property-info-deals') {
            return <PropertyInfo {...AllProps} Minimumloanamount={this.loanAmount} />
        }

        if (currentState == 'choose-postcode') {
            return <FunnelChooseBroker {...AllProps} />
        }

        if (currentState == 'deal-listing') {
            return <FunnelDeals  {...AllProps} dealClickHandler={this.dealClickHandler} />
        }

        if (currentState == 'deal-info') {
            let dealId = this.state.deal;
            log(dealId, "Deal id to be passed");
            return <FunnelDealDetails  {...AllProps} dealID={this.state.deal} dealClickHandler={this.dealClickHandler} />
        }

        if (currentState == 'mortgage-broker-finder') {
            return <FunnelBrokers  {...AllProps} handleBrokerClick={this.handleBrokerClick} />
        }

        if (currentState == 'broker-profile') {
            let brokerId = this.state.broker;
            return <FunnelBrokerDetails brokerId={brokerId} {...AllProps} />
        }

        if (currentState == 'save-your-progress') {
            return <SaveYourProgress {...AllProps} />
        }

        if (currentState == 'appointment') {
            return <BookAppointment {...AllProps} />
        }

        if (currentState == 'thank-you') {
            return <ThankYou {...AllProps} />
        }

        if (currentState == 'dashboard') {
            return <WelComeToDashboard {...AllProps} />
        }

        return <h1>Some Error Occurred</h1>
    }

    onStateChange(slug) {
        log("on state change called..");

        if (!!slug) {
            log(slug);
            if (this.state.steps.current != slug) {
                this.setState({
                    steps: { ...this.state.steps, current: slug }
                })
            }
        }

        this.cookieSet('funnelData', this.state);
    }

    render() {

        let slug = '';

        if (typeof window != 'undefined') {
            slug = window.location.hash.replace('#/', '');

            if (!!slug) {
                log(this.state.stepsFollowed.indexOf(slug), 'render direct access');
                if (this.state.stepsFollowed.indexOf(slug) == -1) {
                    window.location.hash = '#/';
                }
            }
        }

        this.onStateChange(slug);

        let AllProps = {
            handleChangeStep: this.handleClick,
            currentState: this.state,
            backBtnHandler: this.backBtnHandler,
            funnelData: this.state.funnelData,
            skipBtnHandler: this.skipBtnHandler,
            getSteps: this.getSteps,
            handleBookAppointmentData: this.handleBookAppointmentData,
            handleBrokerContactMe: this.handleBrokerContactMe,
            handleCalculatorClick: this.handleCalculatorClick,
            handleSidebarClick: this.handleSidebarClick,
            handleSaveYourProgress: this.handleSaveYourProgress,
            continueChooseBrokerHandler: this.continueChooseBrokerHandler,
            preferSomeoneElseHandler: this.preferSomeoneElseHandler,
            postcodeUpdateHandler: this.postcodeUpdateHandler,
            dealSkipClickHandler: this.dealSkipClickHandler
        };

        log("I'm in render")


        let view = () => (
            <Layout>{null}</Layout>
        );

        if (typeof window !== `undefined`) {

            const HashRouter = require("react-router-dom");

            let Router = HashRouter.HashRouter;

            let Route = HashRouter.Route;

            const borrowerStats = this.borrowerStats.elements.label_leads_count.value;

            view = () => (
                <Router>
                    <section className={'funnelMain'}>
                        <Layout>
                            <Route exact path="/" component={() => <LoanType {...AllProps} content={borrowerStats}  ></LoanType>} />

                            <Route exact path="/new-loan" component={() => <NewLoan {...AllProps} />} />

                            <Route exact path="/refinance" component={() => <NewLoan {...AllProps} />} />

                            <Route exact path="/calculator" component={() => <Calculator {...AllProps} />} />

                            <Route exact path="/property-info" component={() => <PropertyInfo {...AllProps} Minimumloanamount={this.loanAmount} />} />

                            <Route exact path="/deal-listing" component={() => <FunnelDeals  {...AllProps} dealClickHandler={this.dealClickHandler} />} />

                            <Route exact path="/deal-info" component={() => <FunnelDealDetails  {...AllProps} dealID={this.state.deal} dealClickHandler={this.dealClickHandler} />} />

                            <Route exact path="/choose-postcode" component={() => <FunnelChooseBroker {...AllProps} />} />

                            <Route exact path="/mortgage-broker-finder" component={() => <FunnelBrokers  {...AllProps} handleBrokerClick={this.handleBrokerClick} />} />

                            <Route exact path="/broker-profile" component={() => <FunnelBrokerDetails brokerId={this.state.broker} {...AllProps} />} />

                            <Route exact path="/save-your-progress" component={() => <SaveYourProgress {...AllProps} />} />

                            <Route exact path="/appointment" component={() => <BookAppointment {...AllProps} />} />

                            <Route exact path="/thank-you" component={() => <ThankYou {...AllProps} />} />

                            <Route exact path="/dashboard" component={() => <WelComeToDashboard {...AllProps} />} />

                        </Layout>
                    </section>
                </Router>
            )
        }

        return (
            <div>{view()}</div>
        )
    }
}

const StickyBottomBar = (props) => {
    let StickStyle = {
        'display': (props.hideSkip) ? 'none' : 'block'
    }
    return (
        <Fragment>
            <section className={cx('bottom-sticky-buttons-sectionv2 funnelBottomStickyButtonsSection', Styles.funnelBottomStickyButtonsSection)}>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={cx("col-xl-12 col-lg-12 mr-auto ml-auto", "bottom-sticky-buttons")}>
                            <div className={"sticky-getstartd"} style={{ 'display': 'none' }}>
                                <div className={cx("d-flex", "justify-content-center")}>
                                    <p className={"descrption"}>Fill our 2 min. questionaire so we can help you in your home loan project</p>
                                    <div className={cx("sticky-buttons", "btn-right")}>
                                        <a className={cx("btn", "btn-right-link", "active")} href="javascript://">
                                            <span className={"btn-txt"}>Get started</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={"sticky-buttons-main"}>
                                <div className={cx("row")}>
                                    <div className={cx("col-md-2 col-lg-4", "sticky-buttons", "btn-left")}>
                                        <button className={cx("btn", "btn-left-link")} onClick={props.backBtnHandler}>
                                            {/* <i className={cx("fa", "fa-chevron-left")} aria-hidden="true"></i> */}
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-40.000000, -115.000000)" fill="#333333">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M17.7235786,23.7204755 L18.444132,22.9984485 C18.8404319,22.6013317 19.1719795,22.2764214 19.1809189,22.2764214 C19.1898453,22.2764214 20.2156076,23.2967371 21.4603719,24.5438098 L23.7235786,26.8111982 L25.9867852,24.5438098 C27.2315495,23.2967371 28.2572988,22.2764214 28.2662253,22.2764214 C28.2751647,22.2764214 28.6067252,22.6013317 29.0030251,22.9984485 L29.7235786,23.7204755 L26.7276402,26.722027 C25.0798779,28.3728882 23.7280483,29.7235786 23.7235786,29.7235786 C23.7191089,29.7235786 22.3672792,28.3728882 20.719517,26.722027 L17.7235786,23.7204755 Z"
                                                                id="Fill-1-Copy-4" transform="translate(23.723579, 26.000000) rotate(-270.000000) translate(-23.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className={"btn-txt"}>Back</span>
                                        </button>
                                    </div>
                                    <div className={cx("col-md-10 col-lg-8", "sticky-buttons", "btn-right", Styles.skipbtnmain)} style={StickStyle}>
                                        {props.skipBtnText != 'Skip & choose broker for me' && <span className={Styles.btnlabel}>Can't find the deal you want?</span>}
                                        <a className={cx("btn", "btn-right-link", "active", Styles.skipbtn, Styles.active)} href="javascript://" onClick={() => {
                                            props.skipBtnHandler(props.skipFrom)
                                        }}>
                                            <span className={"btn-txt"}>
                                                <span className={'dsktopBtn'}>{props.skipBtnText}</span>
                                                <span className={'mobBtn'}>Skip</span>
                                            </span>
                                            <svg width="8px" height="12px" viewBox="0 0 8 12">
                                                <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="CTAs" transform="translate(-273.000000, -115.000000)" fill="#333333">
                                                        <g id="Group-13-Copy" transform="translate(20.000000, 95.000000)">
                                                            <path d="M250.723579,23.7204755 L251.444132,22.9984485 C251.840432,22.6013317 252.17198,22.2764214 252.180919,22.2764214 C252.189845,22.2764214 253.215608,23.2967371 254.460372,24.5438098 L256.723579,26.8111982 L258.986785,24.5438098 C260.23155,23.2967371 261.257299,22.2764214 261.266225,22.2764214 C261.275165,22.2764214 261.606725,22.6013317 262.003025,22.9984485 L262.723579,23.7204755 L259.72764,26.722027 C258.079878,28.3728882 256.728048,29.7235786 256.723579,29.7235786 C256.719109,29.7235786 255.367279,28.3728882 253.719517,26.722027 L250.723579,23.7204755 Z"
                                                                id="Fill-1-Copy-3" transform="translate(256.723579, 26.000000) rotate(-90.000000) translate(-256.723579, -26.000000) "></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

class FunnerProgressHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { profile_pic_referrer: '' };
        this.myRefProgressHeader = React.createRef()
    }

    componentDidMount() {
        window.scrollTo(0, this.myRefProgressHeader.current.offsetTop)
    }
    componentDidMount() {
           
        if (this.props.currentState.referrerKey != '') {
            // let refkey = this.props.currentState.referrerKey;
            // console.log(refkey);
            // const url = host + `/gatsby-api/get-referrer-pic/` + refkey;

            // Axios.get(url, {

            // }).catch(function (error) {
            //     console.error(error);
            // }).then((response) => {

            //     // console.log(response);
            //     if (typeof response.data.profile_pic_referrer != 'undefined') {

            //         var profile_pic_referrer = "https://s3-ap-southeast-2.amazonaws.com/hashching/uploads/cropper_images/" + response.data.profile_pic_referrer;
            //         console.log(profile_pic_referrer);
            //         this.setState({ profile_pic_referrer: profile_pic_referrer });

            //     }
            // })
        }

    }
    render() {
        let completeClass = Styles.complete;
        let steps = this.props.getSteps() || [];
        let currentlyActive = this.props.active || ''
        let visibleProgressBar = this.props.visible || ''
        const styleVisible = (visibleProgressBar == 'hidden') ? { visibility: 'hidden', opacity: '0', height: '0', paddingTop: '30px' } : {}
        var length = steps.length;
        for (var i = 0; i < length; i++) {
            if (steps[i].labelText == currentlyActive) {
                steps[i].className = Styles.active
                completeClass = ''
            } else {
                steps[i].className = completeClass
            }
        }

        return (


            <section className={cx(Styles.loandealProcessSection)} style={styleVisible} ref={this.myRefProgressHeader}>
                <div className={cx('container')}>
                <div className={cx('row', Styles.headRow)}>
                {/* <div className={cx('col-md-2 col-lg-2', Styles.headleftprt)}>
                    </div> */}

                <div className={cx('col-md-12 text-center', Styles.logoImgmain)}>
                           <img className={cx(Styles.logoImg)} src="https://s3-ap-southeast-2.amazonaws.com/cdn.hashching.com.au/assets/images/frontend/logo-v2.png" />
                        </div>
                        </div>



                    <div className={cx('row')}>
                    
                        <div className={cx('col-12')}>
                            <img src={this.state.profile_pic_referrer} />
                        </div>
                        <div className={cx('col-12', Styles.loandealProcessInnr)}>
                            <div className={cx(Styles.loandealProcess)}>
                                <div className={cx('d-flex', 'justify-content-center', Styles.bsWizard)}>
                                    {steps.map((step, index) => (
                                        <div key={index} className={cx(Styles.bsWizardStep, step.className)}>
                                            <div className={cx('row')}>
                                                <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-12', Styles.stepCount)}>
                                                    <div className={cx(Styles.progress)}>
                                                    </div>
                                                    <span className={cx(Styles.progressJoint)}></span>
                                                    <div className={cx(Styles.bsWizardDot)}>
                                                        <span className={cx(Styles.number)}>
                                                            <small className={cx(Styles.count)}>{index + 1}</small>
                                                        </span>
                                                        <span className={cx(Styles.tick)}>
                                                            <small className={cx(Styles.chkicon)}>
                                                                <i className={cx(Styles.fa, Styles.faCheck)}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                </i>
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                                    <div className={cx(Styles.bsWizardStepnum)}><span dangerouslySetInnerHTML={{ __html: step.labelText }}></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className={cx(Styles.bsWizardStep, Styles.complete)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                                            <div className={cx(Styles.progress)}>

                                            </div>
                                            <span className={cx(Styles.progressJoint)}></span>
                                            <div className={cx(Styles.bsWizardDot)}><span className={cx(Styles.number)}>
                                                <small className={cx(Styles.count)}>
                                                    2
                                                    </small></span>
                                                <span className={cx(Styles.tick)}>
                                                    <small className={cx(Styles.chkicon)}><i className={cx(Styles.fa, Styles.faCheck)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </i></small></span>
                                            </div>
                                        </div>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                            <div className={cx(Styles.bsWizardStepnum)}><span>The property</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(Styles.bsWizardStep, Styles.active)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                                            <div className={cx(Styles.progress)}>

                                            </div>
                                            <span className={cx(Styles.progressJoint)}></span>
                                            <div className={cx(Styles.bsWizardDot)}><span className={cx(Styles.number)}>
                                                <small className={cx(Styles.count)}>
                                                    3
                                                    </small></span>
                                                <span className={cx(Styles.tick)}>
                                                    <small className={cx(Styles.chkicon)}><i className={cx(Styles.fa, Styles.faCheck)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </i></small></span>
                                            </div>
                                        </div>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                            <div className={cx(Styles.bsWizardStepnum)}><span>Your rates</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(Styles.bsWizardStep)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                                            <div className={cx(Styles.progress)}>

                                            </div>
                                            <span className={cx(Styles.progressJoint)}></span>
                                            <div className={cx(Styles.bsWizardDot)}><span className={cx(Styles.number)}>
                                                <small className={cx(Styles.count)}>
                                                    4
                                                    </small></span>
                                                <span className={cx(Styles.tick)}>
                                                    <small className={cx(Styles.chkicon)}><i className={cx(Styles.fa, Styles.faCheck)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </i></small></span>
                                            </div>
                                        </div>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                            <div className={cx(Styles.bsWizardStepnum)}><span>Your broker</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className={cx(Styles.bsWizardStep)}> */}
                                    {/* <div className={cx('row')}>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-2', Styles.stepCount)}>
                                            <div className={cx(Styles.progress)}>

                                            </div>
                                           <span className={cx(Styles.progressJoint)}></span>
                                            <div className={cx(Styles.bsWizardDot)}><span className={cx(Styles.number)}><small className={cx(Styles.count)}>
                                                5
                                                    </small></span>
                                                <span className={cx(Styles.tick)}>
                                                    <small className={cx(Styles.chkicon)}><i className={cx(Styles.fa, Styles.faCheck)}>
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </i></small></span>
                                            </div>
                                        </div>
                                        <div className={cx('col-xl-12 col-lg-12 col-sm-12 col-10', Styles.stepText)}>
                                            <div className={cx(Styles.bsWizardStepnum)}><span>Appointment</span></div>
                                        </div>
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const FunnelRightSection = (props) => {

    let Element;

    if (props.section == '1') {
        Element = () => (
            <div className={Styles.loandealStepRightcontnt}>
                <div className={Styles.loantypContnt}>
                    <div className={cx('card', Styles.card)}>
                        <div className={cx('card-body', Styles.cardBody)}>
                            <h3 className={cx(Styles.sectionTitle, Styles.titleWithImg)}>

                                <img className={Styles.titleimg} src={flagIcon} alt="flagIcon" />
                                <span className={Styles.titletxt}> We are a Sydney born company</span>


                            </h3>
                            <p className={Styles.sectionSummry}>Completely <strong>free</strong> to consumers with no locked in contracts, HFG Marketplace connects you directly to verified mortgage brokers who can further negotiate a better rate from the lenders and save you time, hassle and money.</p>

                            <div className={Styles.dealcountStatus}>
                                <div className={Styles.countsDetailMain}>
                                    <span className={Styles.countsDetailIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="45" viewBox="0 0 35 45">
                                            <g fill="none" fillRule="evenodd">
                                                <g fill="#ff8a23">
                                                    <path d="M4.887 9.688c-.004-.054.822-.527 1.57-1.095l-.37.281A12.383 12.383 0 0 1 8.86 7.31l-.45.186c1.096-.446 2.239-.789 3.412-.946l-.482.063c2.142-.278 4.3-.036 6.44.095.999.06 2.005.105 3.006.033.61-.046 1.224-.12 1.823-.251.578-.126 1.118-.336 1.659-.572.587-.256 1.106-.629 1.598-1.034-.469.39-.179 2.007.132 3.167l1.137 4.241.978 3.652c.158.59.299 1.11.358 1.308-.065.122-.231.34-.136.22-.278.348-.59.665-.949.94l.371-.282c-.37.282-.774.51-1.203.687l.449-.186a7.399 7.399 0 0 1-1.794.46c.16-.022.322-.044.482-.064-1.258.165-2.536.099-3.793.013-1.345-.092-2.684-.219-4.034-.23-1.521-.007-3.062.15-4.529.583-1.47.435-3.004.964-4.204 1.893-.52.402-1.787 1.322-1.758 2.047.034.883.632 1.714 1.794 2.495.501-.472.851-.795 1.052-.97.174-.15.349-.295.534-.432l-.371.282a12.252 12.252 0 0 1 2.702-1.534l-.449.186c1.088-.448 2.23-.79 3.395-.95l-.482.064c1.747-.23 3.506-.104 5.25.023.85.064 1.697.13 2.547.153.953.026 1.886-.031 2.83-.167 1.737-.247 3.462-1.063 4.592-2.416.36-.432.695-.88.965-1.368.12-.214.225-.436.31-.663.135-.379.121-.795.044-1.18-.006-.023-.009-.048-.015-.071-.029-.12-.065-.242-.097-.361l-.514-1.92-.801-2.988-.903-3.37-.833-3.107-.588-2.195-.145-.542c-.245-.915-.948-1.592-1.923-1.717a2.224 2.224 0 0 0-1.677.46 2.85 2.85 0 0 0-.6.66 7.05 7.05 0 0 1-.192.264c.096-.12.187-.24.283-.36-.28.356-.596.674-.958.95l.371-.282c-.352.265-.74.483-1.148.654l.449-.186a7.417 7.417 0 0 1-1.836.47c.16-.02.322-.043.482-.064-1.404.18-2.828.087-4.23-.008-1.51-.106-3.026-.233-4.546-.165-3.318.145-6.613 1.331-9.163 3.462-.38.318-.734.66-1.064 1.026-.13.141-.257.288-.382.435-.149.174-.297.349-.434.535-.375.52 3.245 1.097 3.265 1.252z" />
                                                    <path d="M.654 7.652l.211.79.575 2.144.853 3.186 1.038 3.873L4.47 21.89l1.147 4.281 1.074 4.01.904 3.373.65 2.426c.103.385.202.767.308 1.15l.014.052c.117.435.436.842.834 1.068.384.214.94.302 1.372.165.446-.142.877-.41 1.106-.83.225-.417.312-.878.188-1.341l-.212-.79-.574-2.144-.854-3.186-1.038-3.873-1.137-4.244-1.147-4.282-1.074-4.01-.904-3.372-.65-2.427c-.104-.384-.202-.767-.309-1.15l-.013-.051a1.809 1.809 0 0 0-.834-1.068c-.384-.215-.94-.303-1.372-.165-.446.141-.877.41-1.106.829-.225.421-.311.882-.188 1.341z" />
                                                </g>
                                                <path stroke="#ff8a23" strokeLinecap="round" strokeWidth="4" d="M5.415 42.976h27.574" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className={Styles.countsDetail}>
                                        <span className={cx(Styles.digit)}>2015</span>
                                        <span className={cx(Styles.digitInfo)}>The year founded  </span>
                                    </span>
                                </div>
                                <div className={cx(Styles.countsDetailMain)}>
                                    <span className={cx(Styles.countsDetailIcon)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="42" viewBox="0 0 52 42">
                                            <g fill="#ff8a23" fillRule="nonzero">
                                                <path d="M16.774 17.989v-2.14c3.174 0 5.785-2.61 5.785-5.784S19.948 4.28 16.774 4.28c-3.173 0-5.784 2.61-5.784 5.759.039 3.23 2.594 5.81 5.784 5.81v2.14zm0 0v2.14c-5.554 0-9.997-4.484-10.064-10.064C6.71 4.527 11.237 0 16.774 0 22.312 0 26.84 4.527 26.84 10.065c0 5.537-4.527 10.064-10.065 10.064v-2.14zM36.903 20.425v-2.22a3.957 3.957 0 0 0 3.946-3.947c0-2.118-1.827-3.946-3.946-3.946a3.957 3.957 0 0 0-3.946 3.946c0 2.14 1.796 3.946 3.946 3.946v2.22zm0 0v2.22c-4.608 0-8.387-3.8-8.387-8.387 0-4.601 3.742-8.387 8.387-8.387 4.571 0 8.387 3.816 8.387 8.387 0 4.602-3.741 8.387-8.387 8.387v-2.22zM51.303 39.695c-.052 1.225-1.074 2.175-2.284 2.122-1.209-.052-2.147-1.088-2.095-2.312.297-7.02-3.294-9.403-10.633-9.403-1.21 0-2.192-.994-2.192-2.219 0-1.225.981-2.219 2.192-2.219 9.516 0 15.44 3.931 15.012 14.031zM34.32 39.406c.007 1.226-.968 2.225-2.178 2.233-1.21.008-2.198-.98-2.205-2.205-.045-7.184-3.623-10.872-12.709-10.872-7.595 0-12.752 3.987-12.708 10.844.008 1.225-.967 2.225-2.177 2.233-1.21.008-2.198-.979-2.205-2.204-.062-9.629 7.287-15.31 17.09-15.31 11.52 0 17.031 5.682 17.091 15.281z" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className={cx(Styles.countsDetail)}>
                                        {/* <span className={cx(Styles.digit)}>{props.content}</span> */}
                                        <span className={cx(Styles.digit)}>60,000</span>
                                        <span className={cx(Styles.digitInfo)}>Borrowers helped  </span>
                                    </span>
                                </div>
                                <div className={cx(Styles.countsDetailMain)}>
                                    <span className={cx(Styles.countsDetailIcon)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="46" viewBox="0 0 41 46">
                                            <g fill="#ff8a23" fillRule="evenodd">
                                                <path d="M22.353 41.176l-2.086.853-1.635-.74C9.89 37.426 4.194 29.937 4.194 20.332v-7.692l16.242-9.037L37.13 12.64v7.464c-.056 9.719-5.809 17.265-14.777 21.073zm17.848-29.349c0-.76-.431-1.468-1.13-1.794L21.045.245c-.645-.327-1.399-.327-1.99 0L1.512 10.033c-.7.326-1.076 1.033-1.076 1.794v8.693c0 10.876 6.403 19.564 16.304 23.915l2.421 1.033c.269.163.538.163.807.163.27 0 .538-.054.807-.163l2.744-1.196C33.636 40.03 40.2 31.342 40.2 20.248v-8.42z" />
                                                <path d="M29.725 17.751L19.147 29.725l-.002-.002v.002l-6.611-7.484 2.644-2.993 3.967 4.49 7.936-8.98z" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className={cx(Styles.countsDetail)}>
                                        <span className={cx(Styles.digit)}>2000+</span>
                                        <span className={cx(Styles.digitInfo)}>Certified Brokers  </span>
                                    </span>
                                </div>
                            </div>
                            <div className={cx(Styles.featurdlogoSection)}>
                                <h3 className={cx(Styles.sectionTitle)}>We are featured in</h3>
                                <ul className={cx(Styles.feturedLogo)}>
                                    <li><div className={cx(Styles.featuredLogofinancial, Styles.feturedLogoImgs)}></div></li>
                                    <li><div className={cx(Styles.featuredLogoadviser, Styles.feturedLogoImgs)}></div></li>
                                    <li> <div className={cx(Styles.featuredLogosmh, Styles.feturedLogoImgs)}></div></li>
                                    <li><div className={cx(Styles.featuredLogonews, Styles.feturedLogoImgs)}></div></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        Element = () => (
            <div className={cx(Styles.aboutpropContnt)}>
                <div className={cx('card', Styles.card)}>
                    <div className={cx('card-body', Styles.cardBody)}>
                        <h3 className={cx(Styles.sectionTitle)}>See how much you <br />could borrow </h3>
                        <p className={cx(Styles.sectionSummry)}>Our borrowing calculator estimate how much you could borrow and what property price range you could afford.</p>
                        <p className={cx(Styles.clickmoreCalc)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                <defs>
                                    <filter id="a" width="166.7%" height="166.7%" x="-33.3%" y="-33.3%" filterUnits="objectBoundingBox">
                                        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
                                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                                        <feMerge>
                                            <feMergeNode in="shadowMatrixOuter1" />
                                            <feMergeNode in="SourceGraphic" />
                                        </feMerge>
                                    </filter>
                                    <linearGradient id="b" x1="84.177%" x2="16.321%" y1="8.32%" y2="102.994%">
                                        <stop offset="0%" stopColor="#ffb100" />
                                        <stop offset="100%" stopColor="#ff8a23" />
                                    </linearGradient>
                                </defs>
                                <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(4 2)">
                                    <circle cx="18" cy="18" r="18" fill="url(#b)" fillRule="nonzero" />
                                    <path fill="#FFF" d="M12.133 13.188c0-.294.24-.532.534-.532h10.666c.295 0 .534.238.534.531v2.657a.532.532 0 0 1-.534.531H12.667a.532.532 0 0 1-.534-.531v-2.656zm10.134 7.437a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.066.476 1.066 1.063c0 .586-.477 1.062-1.066 1.062zm0 3.719A1.065 1.065 0 0 1 21.2 23.28c0-.586.478-1.062 1.067-1.062s1.066.476 1.066 1.062c0 .587-.477 1.063-1.066 1.063zM18 20.625a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.067.476 1.067 1.063c0 .586-.478 1.062-1.067 1.062zm0 3.719a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.067.476 1.067 1.062c0 .587-.478 1.063-1.067 1.063zm-4.267-3.719a1.065 1.065 0 0 1-1.066-1.063c0-.586.477-1.062 1.066-1.062.59 0 1.067.476 1.067 1.063 0 .586-.478 1.062-1.067 1.062zm0 3.719a1.065 1.065 0 0 1-1.066-1.063c0-.586.477-1.062 1.066-1.062.59 0 1.067.476 1.067 1.062 0 .587-.478 1.063-1.067 1.063zM12.133 27h11.734A2.13 2.13 0 0 0 26 24.875v-12.75A2.13 2.13 0 0 0 23.867 10H12.133A2.13 2.13 0 0 0 10 12.125v12.75A2.13 2.13 0 0 0 12.133 27z" />
                                    <path fill="#FFF" d="M12.133 13.188c0-.294.24-.532.534-.532h10.666c.295 0 .534.238.534.531v2.657a.532.532 0 0 1-.534.531H12.667a.532.532 0 0 1-.534-.531v-2.656zm10.134 7.437a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.066.476 1.066 1.063c0 .586-.477 1.062-1.066 1.062zm0 3.719A1.065 1.065 0 0 1 21.2 23.28c0-.586.478-1.062 1.067-1.062s1.066.476 1.066 1.062c0 .587-.477 1.063-1.066 1.063zM18 20.625a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.067.476 1.067 1.063c0 .586-.478 1.062-1.067 1.062zm0 3.719a1.065 1.065 0 0 1-1.067-1.063c0-.586.478-1.062 1.067-1.062s1.067.476 1.067 1.062c0 .587-.478 1.063-1.067 1.063zm-4.267-3.719a1.065 1.065 0 0 1-1.066-1.063c0-.586.477-1.062 1.066-1.062.59 0 1.067.476 1.067 1.063 0 .586-.478 1.062-1.067 1.062zm0 3.719a1.065 1.065 0 0 1-1.066-1.063c0-.586.477-1.062 1.066-1.062.59 0 1.067.476 1.067 1.062 0 .587-.478 1.063-1.067 1.063zM12.133 27h11.734A2.13 2.13 0 0 0 26 24.875v-12.75A2.13 2.13 0 0 0 23.867 10H12.133A2.13 2.13 0 0 0 10 12.125v12.75A2.13 2.13 0 0 0 12.133 27z" />
                                </g>
                            </svg>

                            <span>Try our <a href="javascript:void(0);" onClick={(event) => {
                                if (!!props.handleSidebarClick) {
                                    props.handleSidebarClick();
                                }
                            }}>Borrowing calculator</a></span>
                        </p>
                    </div>
                </div>
                <div className={cx('card', Styles.card)}>
                    <div className={cx('card-body', Styles.cardBody)}>
                        <h3 className={cx(Styles.sectionTitle)}>Why do we have better <br /> rates?</h3>
                        <p className={cx(Styles.sectionSummry)}>We have 2000+ brokers in our network. They know the ins and out of the market and can get the best deals out-<br />there. <strong>They negotiate for you and will get you <br /> unbeatable rates.</strong></p>
                        <div className={cx(Styles.paneloflenders)}>
                            <h3 className={cx(Styles.sectionTitle)}>Panel of Lenders</h3>

                            <div className={Styles.lendrsImgs}>
                                <div className={cx(Styles.lendrimg, Styles.lendrscommbank)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsing)}> </div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsnab)}></div>
                                {/*  <div className={cx(Styles.lendrimg, Styles.lendrsmacquarie)}> </div> */}
                                <div className={cx(Styles.lendrimg, Styles.lendrsnewcastle)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrscitibank)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsanz)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsvirgin)}></div>
                                <div className={cx(Styles.lendrimg, Styles.lendrsauswide)}></div>
                                {/* <div className={cx(Styles.lendrimg, Styles.lendrswestpac)}> </div> */}
                            </div>
                            <p className={Styles.plusmoreText}>And 100+ more…</p>
                        </div>
                        <h3 className={cx(Styles.sectionTitle)}>Why we don’t show the lender</h3>
                        <p className={cx(Styles.sectionSummry)}>Our highly experienced partner mortgage brokers negotiate a better deal from lenders directly and lenders don&apos;t want their deals to be advertised openly. Speak to our broker and they will disclose the lender to you. Don&apos;t worry, our partner brokers have plenty more deals from other lenders and they will source a better deal most suited to your needs at no cost to you.</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={cx('col-lg-6', Styles.loandealStepRight)}>
            <Element />
        </div>
    )
}

const LoanType = (props) => {

    console.log(props);
    let cookies = new Cookies();
    let flow = cookies.get('flow');
    if (props.currentState.isNoDepositFunnel == true) {
        flow = 'specific'; // only to show the back btn
    }
    return (
        <Fragment>
            {flow != 'get-started' && (<StickyBottomBar {...props} hideSkip={true} />)}
            <section className={cx(Styles.getstartedStepsPage, Styles.pageBgcolor, (flow != 'get-started') ? Styles.stickyBarSpace : '')}>
                <FunnerProgressHeader {...props} active={'Your enquiry'} />
                <section className={Styles.loandealStepSection}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={cx('col-xl-10 col-lg-12 mr-auto ml-auto', Styles.loandealStepsContnt)}>
                            <div className={cx(Styles.magnifyImgMain)}>
                                        <img className={cx(Styles.magnifyImg)} src={hashchingMagnifyingGlass} alt="hashchingMagnifyingGlass" />
                                    </div>
                                <div className={'row'}>
                                    <div className={cx('col-lg-6 mr-auto ml-auto', Styles.loandealStepLeft)}>
                                        <div className={Styles.loandealStepLeftcontnt}>
                                            <div className={cx('card', Styles.card)}>
                                                <div className={cx('card-body', Styles.cardBody)}>
                                                    <h2 className={cx(Styles.sectionTitle)}>What type of home loan <br />are you looking for?</h2>
                                                    <div className={cx('text-center', Styles.sectionButton)}>
                                                        <a id ="newloan" className={cx('btn', Styles.blueBtn, Styles.btn)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('new-loan');
                                                        }} >New Home Loan</a>
                                                        <a id ="refinance"className={cx('btn', Styles.blueBtn, Styles.btn)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('refinance');
                                                        }} >Refinance a Home Loan</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <FunnelRightSection section="1" content={props.content} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </Fragment>
    )
}

const NewLoan = (props) => {

    let removeBrokersButton = false, removeDealsButton = false;
    log(props.currentState.stepsFollowed.indexOf('property-info-deals') != -1 || props.currentState.stepsFollowed.indexOf('property-info-brokers') != -1);
    if (props.currentState.stepsFollowed.indexOf('property-info-deals') != -1 || props.currentState.stepsFollowed.indexOf('property-info-brokers') != -1) {
        if (props.currentState.stepsFollowed.indexOf('property-info-deals') != -1) {
            removeBrokersButton = true;
        } else {
            removeDealsButton = true;
        }
    }

    log(removeBrokersButton);
    log(removeDealsButton);


    return (
        <Fragment>
            <StickyBottomBar {...props} hideSkip={true} />
            <section className={cx(Styles.getstartedStepsPage, Styles.pageBgcolor, Styles.stickyBarSpace)}>
                <FunnerProgressHeader {...props} active={'Your enquiry'} />
                <section className={cx(Styles.loandealStepSection)}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={cx('col-xl-10 col-lg-12 mr-auto ml-auto', Styles.loandealStepsContnt)}>
                                <div className={cx(Styles.magnifyImgMain)}>
                                        <img className={cx(Styles.magnifyImg)} src={hashchingMagnifyingGlass} alt="hashchingMagnifyingGlass" />
                                    </div>
                                <div className={"row"}>
                                    <div className={cx('col-lg-6 mr-auto ml-auto', Styles.loandealStepLeft)}>
                                        <div className={cx(Styles.loandealStepLeftcontnt)}>
                                            <div className={cx('card', Styles.card)}>
                                                <div className={cx('card-body', Styles.cardBody)}>
                                                    <h2 className={cx(Styles.sectionTitle)}>I would like to find</h2>
                                                    <div className={cx('text-center', Styles.sectionButton)}>
                                                        {/* <a className={cx('btn', Styles.blueBtn, Styles.btn)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('calculator', '', 'new-loan');
                                                        }} >How much can I borrow</a> */}
                                                        {<a className={cx('btn', Styles.blueBtn, Styles.btn, Styles.btnsize)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('property-info', 'deals');
                                                        }}>Home loan deals available to me</a>}
                                                        {<a className={cx('btn', Styles.blueBtn, Styles.btn, Styles.btnsize)} href="javascript:void(0);" onClick={() => {
                                                            props.handleChangeStep('property-info', 'brokers');
                                                        }} >Right broker in my area</a>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <FunnelRightSection section="1" {...props} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

        </Fragment>
    )
}

class PropertyInfo extends React.Component {

    constructor(props) {
        super(props);
        this.validateForm = this.validateForm.bind(this);
        let DynamicLoanAmt = (props.Minimumloanamount.elements.label_minimumloanamount.value).toString().replace(/[^0-9]/g, '');
        let loanAmountFilled = this.getDefaultValue('amount').toString().replace(/[^0-9]/g, '');
        let deal = (props.funnelData.deal) ? props.funnelData.deal : '';
        if (!deal && this.cookieGet('deal') != undefined) {
            deal = this.cookieGet('deal');
        }
        let showPersonalLoanField =
            (parseInt(loanAmountFilled) >= 4000
                && parseInt(loanAmountFilled) < parseInt(DynamicLoanAmt)
                && props.currentState.flow != 'brokers' && !deal && !props.currentState.fromNoDepositLandingPage) ? true : false;

        this.state = {
            formData: {
                investmentType: this.getDefaultValue('investmentType'),
                amount: this.getDefaultValue('amount'),
                estimated: this.getDefaultValue('estimated'),
                rate: this.getDefaultValue('rate'),
                currentLender: this.getDefaultValue('currentLender'),
                loan_type: props.funnelData.lead_type || '',
                personalLoan: this.getDefaultValue('personalLoan'),
                household_income: this.getDefaultValue('household_income'),
                available_funds: this.getDefaultValue('available_funds')
            },
            errors: {},
            showPersonalLoanField: showPersonalLoanField
        }
        log(props);
    }

    getDefaultValue(value) {
        if (!!this.props.funnelData && !!this.props.funnelData.aboutProperty) {
            return this.props.funnelData.aboutProperty[value];
        }
        return ''
    }

    validNumber(value) {
        var regex = /^[0-9,]+$/;
        return (!regex.test(value)) ? false : true;
    }
    handleKeyPress(target) {
        if (target.charCode == 13) {
            // alert('Enter clicked!!!'); 
            document.getElementById('Nextbtn').click();

        }
    }

    validateForm(event, validation = 'all', value = '') {

        let formData = this.state.formData;

        let LoanType = formData.loan_type

        let investmentType = formData.investmentType;

        let estimated = formData.estimated;

        let personalLoan = formData.personalLoan;

        let amount = formData.amount;

        let rate = formData.rate;

        let currentLender = formData.currentLender;

        let household_income = formData.household_income;

        let available_funds = formData.available_funds;

        let newamount = '';
        
        console.log(formData.estimated);
        if(LoanType != 'refinance')
        {
            amount = '';
            formData.amount = '';
            
        }
        if(LoanType != 'refinance' && amount == '' && available_funds != '' && estimated != '')
        {

          if(parseInt(estimated) > parseInt(available_funds))
          {
            newamount =  estimated - available_funds;
            console.log(newamount);
            newamount =  newamount.toString();
            amount = newamount;
            formData.amount = amount;
          }
          
        }

        if (validation == 'all' || validation == 'investmentType') {

            if (validation != 'all') { // to trigger single validation on the input
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'investmentType': value }
                    }
                })
            } else { // to trigger when next button is clicked
                value = formData.investmentType;
            }

            if (!(!!value)) {
                let errors = this.state.errors;
                errors.investmentType = "Investment type required.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.investmentType;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'amount') {

            if (validation != 'all') {

                value = value.replace(/[^0-9]/g, '')

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'amount': value }
                    }
                })
            } else {
                value = formData.amount;
            }
            const minLoan = (this.props.Minimumloanamount.elements.label_minimumloanamount.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

            /******** Check condition to show personal loan field***/
            let deal = (this.props.funnelData.deal) ? this.props.funnelData.deal : '';
            if (!deal && this.cookieGet('deal') != undefined) {
                deal = this.cookieGet('deal');
            }
            let showPersonalLoanField = (parseInt(value) >= 4000 && parseInt(value) < parseInt(minLoan.replace(/[^0-9]/g, '')) && this.props.currentState.flow != 'brokers' && !deal && !this.props.currentState.fromNoDepositLandingPage) ? true : false;
            this.setState({ showPersonalLoanField: showPersonalLoanField })
            if (!showPersonalLoanField) {
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'personalLoan': '' }
                    }
                })
            }
            /*********************ends*************************/

            let errors = this.state.errors;
            // Minimum loan amount allowed is 70, 000

            if (LoanType == 'refinance' &&  (!(!!value))) {
                errors.amount = "Please enter amount.";
                this.setState({
                    errors: errors
                })
            } else if (LoanType == 'refinance' && (!validator.isInt(value, { allow_leading_zeroes: false }) || value <= 0)) {
                errors.amount = "Please enter a valid amount";
            } else if (LoanType == 'refinance' && (parseInt(value) < parseInt(minLoan.replace(/[^0-9]/g, '')) && this.props.currentState.flow != 'brokers' && (
                (!showPersonalLoanField && validation == 'all')
                ||
                (showPersonalLoanField && personalLoan == 'No')
            )
            )) {
                errors.amount = 'Minimum loan amount allowed is $' + minLoan;
            }
            else if (LoanType == 'refinance' && (parseInt(value) < parseInt(minLoan.replace(/[^0-9]/g, '')) && this.props.currentState.flow == 'brokers')) {
                errors.amount = 'Minimum loan amount allowed is $' + minLoan;
            }
            else {
                if(LoanType == 'refinance')
                {
                    let errors = this.state.errors;
                delete errors.amount;
                this.setState({
                    errors: errors
                })
                }
                
            }

        }
        if (LoanType != 'refinance' &&  (validation == 'all' || validation == 'available_funds')) {

            if (validation != 'all') {

                value = value.replace(/[^0-9]/g, '')

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'available_funds': value }
                    }
                })
            } else {
                value = formData.available_funds;
            }
            
            let errors = this.state.errors;

            if (!(!!value)) {
                errors.available_funds = "Please enter deposit amount.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.available_funds;
                this.setState({
                    errors: errors
                })
            }

        }
        if (this.state.showPersonalLoanField && (validation == 'all' || validation == 'personalLoan')) {

            if (validation != 'all') { // to trigger single validation on the input
                let valuenew = value
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'personalLoan': valuenew }
                    }
                })
            } else { // to trigger when next button is clicked
                value = formData.personalLoan;
            }
            /**********************************************/
            let errors = this.state.errors;
            if (value == "Yes") {
                delete errors.estimated;
            }
            /********************************************/
            if (!(!!value)) {
                errors.personalLoan = "Personal Loan selection required.";
                this.setState({
                    errors: errors
                })
            } else {
                delete errors.personalLoan;
                /**** Loan amount validation ****/
                const minLoanAmt = (this.props.Minimumloanamount.elements.label_minimumloanamount.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if (parseInt(amount) < parseInt(minLoanAmt.replace(/[^0-9]/g, '')) && value == 'No') {
                    // delete errors.amount;                   
                    // errors.amount = 'Minimum loan amount allowed is $' + minLoanAmt; 
                    value = minLoanAmt.replace(/[^0-9]/g, '')
                    this.setState((state) => {
                        return {
                            formData: { ...state.formData, 'amount': value },
                            showPersonalLoanField: false
                        }
                    })

                }
                else {
                    delete errors.amount;
                }
                /////////////////////////////
                this.setState({
                    errors: errors
                })

            }
        }

        //duplicate code
        /* if (validation == 'all' || validation == 'amount') {
 
             if (validation != 'all') {
 
                 value = value.replace(/[^0-9]/g, '')
 
                 this.setState((state) => {
                     return {
                         formData: { ...state.formData, 'amount': value }
                     }
                 })
             } else {
                 value = formData.amount;
             }
 
             let errors = this.state.errors;
             // Minimum loan amount allowed is 70, 000
             const minLoan = this.props.Minimumloanamount.elements.label_minimumloanamount.value;
 
             if (!(!!value)) {
                 errors.amount = "Please enter loan amount.";
                 this.setState({
                     errors: errors
                 })
             } else if (!validator.isInt(value, { allow_leading_zeroes: false }) || value <= 0) {
                 errors.amount = "Please enter a valid amount";
             } else if (value < minLoan) {
                 errors.amount = `Minimum loan amount allowed is $${(minLoan).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
             } else {
                 let errors = this.state.errors;
                 delete errors.amount;
                 this.setState({
                     errors: errors
                 })
             }
         }*/
        /* validate property only if its not personal Loan */
        if (
            (!this.state.showPersonalLoanField || (this.state.showPersonalLoanField && this.state.formData.personalLoan != "Yes"))
            &&
            (validation == 'all' || validation == 'estimated')
        ) {

            if (validation != 'all') {

                value = value.replace(/[^0-9]/g, '')

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'estimated': value }
                    }
                })
            } else {
                value = formData.estimated;
            }


            let errors = this.state.errors;

            if (!(!!value)) {
                errors.estimated = "Please enter property value.";
                this.setState({
                    errors: errors
                })
            } else if (!validator.isInt(value, { allow_leading_zeroes: false }) || value <= 0) {
                errors.estimated = "Please enter a valid amount";
            }
             else if (LoanType == 'refinance' && parseInt(value) > 0 && parseInt(value) < parseInt(amount)) {
                errors.estimated = "Your property value should be greater or equal to the mortgage loan amount.";
            }
            // else if (LoanType != 'refinance' && parseInt(value) > 0 && parseInt(value) <= parseInt(available_funds)) {
            //     errors.estimated = "Please enter property value more than deposit amount.";
            // } 
            else if (LoanType == 'refinance' && parseInt(value) > 0 && parseInt(value) < parseInt(amount)+50000) {
                errors.estimated = "Your property must be $50,000 greater than the mortgage loan amount.";
            }
            else if (LoanType != 'refinance' && parseInt(value) > 0 && parseInt(value) < parseInt(available_funds)+50000) {
                errors.estimated = "Property value must be $50,000 greater than the deposit amount.";
            } 
            else {
                let errors = this.state.errors;
                delete errors.estimated;
                this.setState({
                    errors: errors
                })
            }
        }

        if (LoanType == 'refinance' && (validation == 'all' || validation == 'rate')) {

            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'rate': value }
                    }
                })

            } else {
                value = formData.rate;
            }

            let errors = this.state.errors;

            log(validator.isInt(value));

            log('converted ' + Number(value))

            if (!(!!value)) {
                errors.rate = "Please enter rate.";
                this.setState({
                    errors: errors
                })
            } else if (value <= 0 || value >= 100) {
                errors.rate = "Please enter a valid rate";
            } else if (isNaN(Number(value))) {
                errors.rate = "Please enter a valid rate";
            } else if (validator.isInt(value) && !validator.isInt(value, { allow_leading_zeroes: false })) {
                errors.rate = "Please enter a valid rate";
            } else {
                let errors = this.state.errors;
                delete errors.rate;
                this.setState({
                    errors: errors
                })
            }
        }

        if (LoanType == 'refinance' && (validation == 'all' || validation == 'currentLender')) {

            if (validation != 'all') {

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'currentLender': value }
                    }
                })

            } else {
                value = formData.currentLender;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.currentLender = "Please enter current Lender.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.currentLender;
                this.setState({
                    errors: errors
                })
            }
        }
         if ((validation == 'all' || validation == 'household_income')) {

            if (validation != 'all') {

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'household_income': value }
                    }
                })

            } else {
                value = formData.household_income;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.household_income = "Please enter household income.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.household_income;
                this.setState({
                    errors: errors
                })
            }
        }

        let allErrors = this.state.errors;

        if (Object.keys(allErrors).length === 0
            && allErrors.constructor === Object && !validator.isEmpty(investmentType)
            && !validator.isEmpty(amount) &&
            (this.state.formData.personalLoan == "Yes" || !validator.isEmpty(estimated))
            && validation == 'all') {

            let currentStep = this.props.currentState.steps;
            let flow = this.props.currentState.flow;

            if (flow == 'brokers' || flow == 'both') {
                this.props.handleChangeStep('choose-postcode', this.state.formData);
            }
            let deal = null;

            if (this.cookieGet('deal') != undefined) {
                deal = this.cookieGet('deal');
            }

            if (flow == 'deals') {
                if (this.state.formData.personalLoan != "Yes" && deal == null) {
                    this.props.handleChangeStep('save-your-progress', this.state.formData);
                } else {
                    this.props.handleChangeStep('appointment', this.state.formData);
                }
            }
        }

    }
    cookieGet(variable) {
        let cookies = new Cookies();
        return cookies.get(variable)
    }

    render() {

        let activeClass, toLive = '', toInvest = '', investmentType = '';
        investmentType = this.state.formData.investmentType;

        if (investmentType == 'to-live') {
            toLive = Styles.active;
            toInvest = ''
        }

        if (investmentType == 'to-invest') {
            toInvest = Styles.active;
            toLive = ''
        }
        /// personal loan selections
        let personalLoan = '', personalLoanYes = '', personalLoanNo = '';
        personalLoan = this.state.formData.personalLoan;
        if (personalLoan == 'Yes') {
            personalLoanYes = Styles.active;
            personalLoanNo = ''
        }

        if (personalLoan == 'No') {
            personalLoanNo = Styles.active;
            personalLoanYes = ''
        }
        let personalLoanInfo = "A personal loan is a smaller loan than a mortgage and is generally used to finance a car, renovations to a home, consolidation of debt etc. Most lenders do not finance home loans under $" + (this.props.Minimumloanamount.elements.label_minimumloanamount.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".";
        return (
            <Fragment>
                <StickyBottomBar {...this.props} hideSkip={true}></StickyBottomBar>
                <section className={cx(Styles.getstartedStepsPage, Styles.pageBgcolor, Styles.stickyBarSpace)}>
                    <FunnerProgressHeader {...this.props} active={'The property'} />
                    <section className={cx(Styles.loandealStepSection)}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={cx('col-xl-10 col-lg-12 mr-auto ml-auto', Styles.loandealStepsContnt)}>
                                <div className={cx(Styles.magnifyImgMain)}>
                                        <img className={cx(Styles.magnifyImg)} src={hashchingMagnifyingGlass} alt="hashchingMagnifyingGlass" />
                                    </div>
                                    <div className={'row'}>
                                        <div className={cx('col-lg-6 mr-auto ml-auto', Styles.loandealStepLeft)}>
                                            <div className={cx(Styles.loandealStepLeftcontnt, Styles.aboutStepContnt)}>
                                                <div className={cx('card', Styles.card)}>
                                                    <div className={cx('card-body', Styles.cardBody)}>
                                                        <h2 className={cx(Styles.sectionTitle)}>About the property</h2>

                                                        <div className={Styles.formFields}>
                                                            <form onSubmit={(event) => {
                                                                log(event);
                                                            }}>
                                                                <div className={Styles.proprtyFields}>
                                                                   <div className={cx('form-group', Styles.formGroup)}>
                                                                        <label>How much is your household income?</label>
                                                                        <select name="" value={this.state.formData.household_income} onKeyPress={this.handleKeyPress} className={cx('form-control', Styles.formControl)} onChange={((event) => {
                                                                            this.validateForm(event, 'household_income', event.target.value);
                                                                        })}>
                                                                            <option value="">Select</option>
                                                                
                                                                <option value="< $50,000">&lt; $50,000</option>
                                                                <option value="$50,000 - $100,000">$50,000 - $100,000</option>
                                                                <option value="$100,000 - $200,000">$100,000 - $200,000</option>
                                                                <option value="$200,000+">$200,000+</option>
                                                                        </select>
                                                                        <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.household_income) ? 'block' : 'none', 'width': '100%' }}>
                                                                            {this.state.errors.household_income}
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('form-group', Styles.formGroup)}>
                                                                        <label>Purpose of the loan</label>
                                                                        <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                                            <div className={cx('btn-group', Styles.btnGroup)} role="group">

                                                                                <label className={cx('btn', Styles.btn, toLive)}>To Live In
                                                                                    <input type="radio" name="investment" value="to-live" onChange={((event) => {
                                                                                        this.validateForm(event, 'investmentType', event.target.value);
                                                                                    })} />
                                                                                </label>
                                                                                <label className={cx('btn', Styles.btn, toInvest)}>To Invest
                                                                                    <input type="radio" name="investment" value="to-invest" onChange={((event) => {
                                                                                        this.validateForm(event, 'investmentType', event.target.value);
                                                                                    })} />
                                                                                </label>
                                                                            </div>
                                                                            <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.investmentType) ? 'block' : 'none' }}>
                                                                                You must agree before submitting.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                       <div className={cx('form-group', Styles.formGroup)} style={{ display: (this.state.formData.loan_type != 'refinance') ? 'block' : 'none' }}>
                                                                        <label>How much deposit do you have?</label>
                                                                        <div className={cx('input-group', Styles.inputGroup)}>
                                                                            <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                                <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                            </div>
                                                                            <input type="text" className={cx('form-control', Styles.formControl)} onKeyPress={this.handleKeyPress} value={this.state.formData.available_funds.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} placeholder="Please enter deposit amount" onChange={((event) => {
                                                                                        this.validateForm(event, 'available_funds', event.target.value);
                                                                                    })} /> 
                                                                            <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.available_funds) ? 'block' : 'none', 'width': '100%' }}>
                                                                                {this.state.errors.available_funds}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('form-group', Styles.formGroup)} style={{ display: (this.state.formData.loan_type == 'refinance') ? 'block' : 'none' }}>
                                                                        <label>Estimated Loan Amount</label>
                                                                        <div className={cx('input-group', Styles.inputGroup)}>
                                                                            <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                                <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                            </div>
                                                                            <input type="text" className={cx('form-control', Styles.formControl)} onKeyPress={this.handleKeyPress} value={this.state.formData.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} placeholder="How much do you want to borrow?" onChange={((event) => {
                                                                                this.validateForm(event, 'amount', event.target.value);
                                                                            })} onBlur={(event) => {
                                                                                log(this.props.currentState, "focused out..");
                                                                                if (!!event.target.value && this.props.currentState.isNoDepositFunnel == true) {
                                                                                    log(event.target.value, "value of loan amount")
                                                                                    this.validateForm(event, 'estimated', event.target.value);
                                                                                }
                                                                            }} />
                                                                            <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.amount) ? 'block' : 'none', 'width': '100%' }}>
                                                                                {this.state.errors.amount}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (this.state.showPersonalLoanField) ? (
                                                                            <div className={cx('form-group', Styles.formGroup, Styles.personalLoanField)}>

                                                                                <label>

                                                                                    <span className={cx(Styles.labeltxt)}>Is this a personal loan?</span>
                                                                                    <span className={cx(Styles.questionMark)} data-tip="React-tooltip" >
                                                                                        <img src={questionMark} alt="question mark" />
                                                                                    </span>
                                                                                </label>
                                                                                <div className={cx(Styles.personalLoantooltip)}>
                                                                                    <ReactTooltip multiline={true} place='top' className="hashInfo" effect='solid' getContent={() => { return personalLoanInfo }} />
                                                                                </div>
                                                                                <div className={cx(Styles.mobWidth, Styles.fieldBtns)}>
                                                                                    <div className={cx('btn-group', Styles.btnGroup)} role="group">

                                                                                        <label className={cx('btn', Styles.btn, personalLoanYes)}>Yes
                                                                                        <input type="radio" name="personalLoan" onKeyPress={this.handleKeyPress} value="Yes" onChange={((event) => {
                                                                                                this.validateForm(event, 'personalLoan', event.target.value);
                                                                                            })} />
                                                                                        </label>
                                                                                        <label className={cx('btn', Styles.btn, personalLoanNo)}>No
                                                                                        <input type="radio" name="personalLoan" onKeyPress={this.handleKeyPress} value="No" onChange={((event) => {
                                                                                                this.validateForm(event, 'personalLoan', event.target.value);
                                                                                            })} />
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.personalLoan) ? 'block' : 'none' }}>
                                                                                        You must agree before submitting.
                                                                                </div>
                                                                                </div>
                                                                            </div>) : ''
                                                                    }
                                                                    {
                                                                        (!this.state.showPersonalLoanField || (this.state.showPersonalLoanField && this.state.formData.personalLoan != "Yes")) ?
                                                                            (<div className={cx('form-group', Styles.formGroup)}>
                                                                                <label>Estimated Property Value</label>
                                                                                <div className={cx('input-group', Styles.inputGroup)}>
                                                                                    <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                                        <div className={cx('input-group-text', Styles.inputGroupText)}>$</div>
                                                                                    </div>
                                                                                    <input type="text" className={cx('form-control', Styles.formControl)} onKeyPress={this.handleKeyPress} value={this.state.formData.estimated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} placeholder="Enter an average value of property" onChange={((event) => {
                                                                                        this.validateForm(event, 'estimated', event.target.value);
                                                                                    })} />
                                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.estimated) ? 'block' : 'none', 'width': '100%' }}>
                                                                                        {this.state.errors.estimated}
                                                                                    </div>

                                                                                </div>
                                                                            </div>) : ''
                                                                    }

                                                                    <div className={cx('form-group', Styles.formGroup)} style={{ display: (this.state.formData.loan_type == 'refinance') ? 'block' : 'none' }}>
                                                                        <label>Current Interest Rate</label>
                                                                        <div className={cx('input-group', Styles.inputGroup, Styles.rightinputGroup)}>

                                                                            <input type="text" className={cx('form-control', Styles.formControl)} onKeyPress={this.handleKeyPress} placeholder="" value={this.state.formData.rate} onChange={((event) => {
                                                                                this.validateForm(event, 'rate', event.target.value);
                                                                            })} />
                                                                            <div className={cx('input-group-append', Styles.inputGroupAppend)}>
                                                                                <div className={cx('input-group-text', Styles.inputGroupText)}>%</div>
                                                                            </div>
                                                                            <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.rate) ? 'block' : 'none', 'width': '100%' }}>
                                                                                {this.state.errors.rate}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('form-group', Styles.formGroup)} style={{ display: (this.state.formData.loan_type == 'refinance') ? 'block' : 'none' }}>
                                                                        <label>Current Lender</label>
                                                                        <select name="" value={this.state.formData.currentLender} onKeyPress={this.handleKeyPress} className={cx('form-control', Styles.formControl)} onChange={((event) => {
                                                                            this.validateForm(event, 'currentLender', event.target.value);
                                                                        })}>
                                                                            <option value="">Select</option>
                                                                            <option>CBA</option>
                                                                            <option>NAB</option>
                                                                            <option>ANZ</option>
                                                                            <option>Westpac</option>
                                                                            <option>Other</option>
                                                                        </select>
                                                                        <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.currentLender) ? 'block' : 'none', 'width': '100%' }}>
                                                                            {this.state.errors.currentLender}
                                                                        </div>
                                                                    </div>
                                                                    <div className={cx('text-center', Styles.fieldsubmtBtn)}>
                                                                        <a className={cx('btn', Styles.blueBtn, Styles.btn)} id="Nextbtn" href="javascript:void(0);" onClick={this.validateForm}>Next</a>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <FunnelRightSection {...this.props} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

            </Fragment>
        )
    }
}

class BookAppointment extends React.Component {

    constructor(props) {

        super(props);

        this.validateForm = this.validateForm.bind(this);
        this.timeSelectionDropdown = this.timeSelectionDropdown.bind(this);
        this.submitLead = this.submitLead.bind(this);
        this.onChangePostcode = this.onChangePostcode.bind(this);
        this.onPostcodeSelect = this.onPostcodeSelect.bind(this);
        this.onPostcodeKeyDown = this.onPostcodeKeyDown.bind(this);
        this.clearPostcodeSelection = this.clearPostcodeSelection.bind(this);
        this.cookieSetNew = this.cookieSetNew.bind(this);
        

        this.state = {
            formData: {
                best_day: moment(this.getSpecificDates()[0]).format('YYYY/MM/DD'),
                best_time: '',
                first_name: '',
                email: '',
                mobile: '',
                agree: false,
                gaid: '',
                userInputPostcode: ''
            },
            ar_best_time: [],
            errors: {},
            verifiedNumber: '',
            codeVerified: false,
            showCodeVerification: false,
            uuid: '',
            mobileCode: '',
            submissionError: false,
            submissionErrorMessage: '',
            ajaxCallStatus: false,
            suggestions: [],
            userInputPostcode: "",
            suggestionClass: 'closeSuggestionClass',
            postcodeListmessage: 'Please select postcode from list',
            requestpostcode: '',
            postcodeId: '',
            postcode: null,
            
        }
    }

    convertTime12to24(time12h) {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return hours + ':' + minutes;
    }

    cookieGet(variable) {
        let cookies = new Cookies();
        return cookies.get(variable)
    }

    submitLead() {

        let pCode = this.cookieGet('pCode');

        let postcode = this.cookieGet('postcode');

        let sCode = this.cookieGet('stateCode');

        let suburbcode = this.cookieGet('suburb');

        this.setState({
            submissionError: false,
            submissionErrorMessage: ''
        })

        let formData = this.state.formData

        let leadInfo = {}
        let datanew = {}
        datanew.code = this.state.mobileCode;
        datanew.codeKey = this.state.uuid;

        let timeParts = formData.best_time.split('-')[0];

        let contactTime = this.convertTime12to24(timeParts);

        log(this.props.funnelData);

        let suburb = (!!this.props.funnelData.suburb) ? this.props.funnelData.suburb : '';

        let state = (!!this.props.funnelData.state) ? this.props.funnelData.state : '';

        let loanType = (this.props.funnelData.lead_type == 'refinance') ? 'Refinance' : 'New Loan';

        let amount = (this.props.funnelData.aboutProperty.amount > 0) ? this.props.funnelData.aboutProperty.amount : 0;

        let available_funds = (this.props.funnelData.aboutProperty.available_funds > 0) ? this.props.funnelData.aboutProperty.available_funds : 0;

        let investmentType = (this.props.funnelData.aboutProperty.investmentType == 'to-invest') ? 'Investment' : 'Owner Occupied';

        let currentLender = (this.props.funnelData.aboutProperty.currentLender) ? this.props.funnelData.aboutProperty.currentLender : '';

        let rate = (this.props.funnelData.aboutProperty.rate) ? this.props.funnelData.aboutProperty.rate : '';

        let personalLoan = (!!this.props.funnelData.aboutProperty.personalLoan) ? this.props.funnelData.aboutProperty.personalLoan : '';

        let estimated = (this.props.funnelData.aboutProperty.estimated > 0 && personalLoan != "Yes") ? this.props.funnelData.aboutProperty.estimated : 0;

        let broker = (this.props.funnelData.broker) ? this.props.funnelData.broker : '';

        let deal = (this.props.funnelData.deal) ? this.props.funnelData.deal : '';

        let postcodeId = (this.props.currentState.postcodeId) ? this.props.currentState.postcodeId : '';

        let postcodeSave = '';

        let household_income = (this.props.funnelData.aboutProperty.household_income) ? this.props.funnelData.aboutProperty.household_income : '';

        if (!deal && this.cookieGet('deal') != undefined) {
            deal = this.cookieGet('deal');
        }

        if (!broker && this.cookieGet('broker') != undefined) {
            broker = this.cookieGet('broker');
        }

        if (!this.props.currentState.postcodeId && this.cookieGet('postcode_id') != undefined) {
            postcodeId = this.cookieGet('postcode_id');
        }
        if (this.state.postcodeId && this.state.postcodeId != '') {
            postcodeId = this.state.postcodeId;
        }

        if (!!this.props.funnelData.postcode) {
            postcodeSave = this.props.funnelData.postcode;
        }

        if (!postcodeSave && !!postcode) {
            postcodeSave = postcode;
        }

        if (!postcodeSave && !!pCode) {
            postcodeSave = pCode;
        }

        if (!suburb) {
            suburb = suburbcode;
        }

        if (!!suburb) {
            leadInfo['suburb'] = suburb;
            datanew.suburb = suburb
        }
        if (!state) {
            state = sCode;
        }

        if (!!state) {
            leadInfo['state'] = state;
            datanew.state = state
        }

        // log(postcodeSave);

        // log(loanType);

        // log(amount);

        // log(estimated);

        // log(investmentType);

        // log(currentLender);

        // log(rate);
        //owner_or_investment_property

        //estimated_loan_amount

        //estimated_property_value

        //loan_type

        //current_lender

        if (!!loanType) {
            leadInfo['loan_type'] = loanType;
            datanew.loan_type = loanType
        }

        if (!!broker) {
            leadInfo['broker_id'] = broker;
            datanew.come_from_broker_id = broker
        }

        if (!!deal) {
            leadInfo['deal_id'] = deal;
            datanew.deal_id = deal
        }

        if (!!amount) {
            leadInfo['estimated_loan_amount'] = amount;
            datanew.loan_amount = amount
        }

        if (!!available_funds) {
            leadInfo['available_funds'] = available_funds;
            datanew.available_funds = available_funds
        }


        if (!!estimated) {
            leadInfo['estimated_property_value'] = estimated;
            datanew.estimated_property_value = estimated
        }

        if (!!investmentType) {
            leadInfo['owner_or_investment_property'] = investmentType;
            datanew.owner_or_investment_property = investmentType
        }
        if (!!personalLoan && !broker && !deal) {
            leadInfo['personal_loan'] = personalLoan;
            datanew.personal_loan = personalLoan
        }

        if (!!currentLender) {
            leadInfo['current_lender'] = currentLender;
            datanew.current_lender = currentLender
        }

        if (!!rate) {
            leadInfo['rate'] = rate;
            datanew.rate = rate
        }
        if (!!household_income) {
            leadInfo['household_income'] = household_income;
            datanew.household_income = household_income
        }

        // leadInfo['key'] = this.props.currentState.referrerKey;
        // leadInfo['first_name'] = formData.first_name;
        // leadInfo['email'] = formData.email;
        // leadInfo['mobile'] = (formData.mobile.toString().length == 9) ? 0 + formData.mobile : formData.mobile;
        // leadInfo['postcode'] = postcodeSave || 2000;
        // leadInfo['contact_day'] = formData.best_day.replace(/\//g, "-");
        // leadInfo['contact_time'] = contactTime;
        // leadInfo['timezone'] =  momentTimezone.tz.guess() || 'Australia/Sydney';
        // leadInfo['utmTags'] = this.props.currentState.utmTags;  
        // leadInfo['accept_policies'] = formData.agree; 
        // leadInfo['pageSource'] = this.props.currentState.pageSource;
        // leadInfo['codeKey'] = this.state.uuid;
        // leadInfo['code'] = this.state.mobileCode;

        datanew.referrer = this.props.currentState.referrerKey;
        datanew.first_name = formData.first_name;
        datanew.email = formData.email;
        datanew.mobile = (formData.mobile.toString().length == 9) ? 0 + formData.mobile : formData.mobile;
        datanew.postcode = postcodeSave || 9999;
        datanew.postcode_id = postcodeId;
        datanew.contact_day = formData.best_day.replace(/\//g, "-");
        datanew.contact_time = contactTime;
        datanew.timezone = momentTimezone.tz.guess() || 'Australia/Sydney';
        datanew.utm_campaign = this.props.currentState.utmTags.utm_campaign;
        datanew.utm_source = this.props.currentState.utmTags.utm_source;
        datanew.utm_medium = this.props.currentState.utmTags.utm_medium;
        datanew.utm_term = this.props.currentState.utmTags.utm_term;
        datanew.accept_policies = formData.agree;
        datanew.page_source = this.props.currentState.pageSource;
        datanew.gaid = this.state.gaid;
        datanew.consent = this.cookieGet('consentcookie');
        console.log(datanew);

        if (typeof (Storage) !== "undefined" && sessionStorage.landingData) {
            let landingData = sessionStorage.landingData
            if (!!landingData) {
                landingData = JSON.parse(landingData);
                datanew.landingData = landingData.landingData;
                log(landingData, "landing page data");
            }
        }


        log('login clicked')
        let data = JSON.stringify({
            // "lead": {
            //     "key": "TOztviu8rm5Fm4FYBKms+kNdeYOnHTWHJeRQ3wqnwZg=",
            //     "first_name": "Pankaj",

            //     "email": "pankaj.comsumer11@mailinator.com",
            //     "mobile": "0488826378",
            //     "postcode": "2000",
            //     "state": "NSW",
            //     "accept_policies": "Yes",
            //     "broker_id": 7,
            //     "estimated_loan_amount": 500000,
            //     "contact_day": "2018-07-23",
            //     "contact_time": "15:40",
            //     "timezone": "Australia/Sydney",
            //     "preferred_lender": "NAB"
            // }
            "lead": leadInfo
        })

        //if(this.state.ajaxCallStatus) return;

        this.setState({ ajaxCallStatus: true })


        document.getElementById("SubmitLeadButton").classList.add('loading');
        Axios.post(host + '/v2/capture-lead', datanew, {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        ).then((response) => {
            document.getElementById("SubmitLeadButton").classList.remove('loading');
            let description = "Unable to submit the lead."
            /******************************/
            // if(response.data.status!='100')
            // {
            //     description = response.data.message;
            // }
            /*********************************/

            // if (typeof response.data.description == 'object') {
            //     description = '';
            //     for (var key in response.data.description) {
            //         if (response.data.description.hasOwnProperty(key)) {
            //             description = description + " " + response.data.description[key];
            //         }
            //     }
            // }

            // if (typeof response.data.description == 'string') {
            //     description = response.data.description;
            // }

            if (response.data.status == '100') {
                let formData = this.state.formData;
                let ref = response.data.lead;

                this.setState({
                    formData: { ...formData, ref: ref }
                })
                formData.ref = ref;
                log(formData);
                //this.props.handleChangeStep('thank-you', formData);
                let cookies = new Cookies()
                cookies.remove('SaveEmail', { path: '/' })
                /*********************************/
                log("funnel cookie removed..");
                if (typeof (Storage) !== "undefined") {
                    sessionStorage.removeItem("landingData");
                }
                removeCookie('funnelData');
                removeCookie('broker');
                removeCookie('deal');
                removeCookie('postcode');
                removeCookie('postcode_id');
                removeCookie('statecode');
                removeCookie('SaveEmail');
                removeCookie('SaveName');
                removeCookie('consentcookie');
                window.location.href = '/thanks-home-loan-enquiry/' + ref;

                /*********************************/

            }
            // else if(response.data.code == '101')
            // {
            //     let description = response.data.message;
            // }
            else {
                this.setState({ ajaxCallStatus: false })
                description = response.data.message;
                this.setState({
                    submissionError: true,
                    submissionErrorMessage: description
                })
            }

        })
    }

    getSpecificDates() {

        let scope = {};
        let i = 0;
        let imax = 0;
        scope.currentDate = new Date();
        scope.currentDate.setTime(scope.currentDate.getTime() + 48 * 60 * 60 * 1000);
        scope.CurrentYear = scope.currentDate.getFullYear();
        scope.CurrentMonth = scope.currentDate.getMonth() + 1;
        scope.CurrentDate = scope.currentDate.getDate();
        scope.Currenthours = scope.currentDate.getHours();
        scope.Currentmints = scope.currentDate.getMinutes();
        if (scope.Currenthours < 19 || (scope.Currenthours == '19' && scope.Currentmints <= '16')) {
            i = 0;
            imax = 6;
            scope.minDate = new Date(
                scope.currentDate.getFullYear(),
                scope.currentDate.getMonth(),
                scope.currentDate.getDate());
        }
        else {
            i = 1;
            imax = 7;
            scope.minDate = new Date(
                scope.currentDate.getFullYear(),
                scope.currentDate.getMonth(),
                scope.currentDate.getDate() + 1);
        }
        scope.MaxDate = new Date(
            scope.currentDate.getFullYear(),
            scope.currentDate.getMonth(),
            scope.currentDate.getDate() + 7);
        scope.specificDate = [];
        for (i; i <= imax; i++) {
            scope.specificDate.push(new Date(
                scope.currentDate.getFullYear(),
                scope.currentDate.getMonth(),
                scope.currentDate.getDate() + i));
        }
        return scope.specificDate
        // log(scope.specificDate);
    }

    validateForm(event, validation = 'all', value = '') {

        if (event) {
            event.preventDefault();
        }

        this.getSpecificDates()

        let formData = this.state.formData;
        let stateData = this.state;

        if (validation == 'all' || validation == 'best_day') {

            if (value) log(this.isValidDate(value.toString()));

            if (validation != 'all') { // to trigger single validation on the input

                let selectedDate = moment(value).format('YYYY/MM/DD');
                if (moment(value).isValid()) {
                    this.setBestTime(selectedDate);
                }
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'best_day': (moment(value).isValid()) ? moment(value).format('YYYY/MM/DD') : '' }
                    }
                })

            } else { // to trigger when next button is clicked
                value = formData.best_day;
            }

            if (!(!!value)) {
                let errors = this.state.errors;
                errors.best_day = "Please select a date.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.best_day;
                this.setState({
                    errors: errors
                })
            }
        }

        // best_time

        if (validation == 'all' || validation == 'best_time') {

            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'best_time': value }
                    }
                })
            } else {
                value = formData.best_time;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.best_time = "Please select time.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.best_time;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'email') {

            if (validation != 'all') {

                // value = value.replace(/[^0-9]/g, '')

                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'email': value }
                    }
                })
            } else {
                value = formData.email;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.email = "Please enter your email.";
                this.setState({
                    errors: errors
                })
            } else if (!validator.isEmail(value)) {
                errors.email = "Please enter a valid email";
            } else {
                let errors = this.state.errors;
                delete errors.email;
                this.setState({
                    errors: errors
                })
            }

        }

        if (validation == 'all' || validation == 'first_name') {

            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'first_name': value }
                    }
                })
            } else {
                value = formData.first_name;
            }

            let errors = this.state.errors;

            if (!(!!value)) {
                errors.first_name = "Please enter first name.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.first_name;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'mobile') {

            if (validation != 'all') {
                this.setState({
                    codeVerified: false
                })
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'mobile': value }
                    }
                })
            } else {
                value = formData.mobile
            }

            let errors = this.state.errors;
            // validMobileNum

            if (!(!!value)) {
                errors.mobile = "Please enter mobile number.";
                this.setState({
                    errors: errors
                })
            }
            //  else if (validation == 'all' && this.validMobileNum(value)) {
            //     if (this.state.verifiedNumber != value) {
            //         errors.mobile = "Please verify your mobile.";
            //     }
            // } 


            else if (!this.validMobileNum(value)) {
                errors.mobile = "Please enter a valid mobile number";
            } else if (validation == 'all' && this.validMobileNum(value)) {
                if (!this.state.mobileCode && !this.state.showCodeVerification) {
                    errors.mobile = "Please verify your mobile.";
                }
            } else {
                let errors = this.state.errors;
                delete errors.mobile;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'agree') {

            if (validation != 'all') {
                value = !this.state.formData.agree;
                this.setState((state) => {
                    return {
                        formData: { ...state.formData, 'agree': value }
                    }
                })
            } else {
                value = formData.agree;
            }

            let errors = this.state.errors;

            if (!value) {
                errors.agree = "This field is required.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.agree;
                this.setState({
                    errors: errors
                })
            }
        }

        if (validation == 'all' || validation == 'mobileCode') {
            if (validation != 'all') {
                this.setState((state) => {
                    return {
                        'mobileCode': value
                    }
                })
            } else {
                value = this.state.mobileCode;
            }


            let errors = this.state.errors;

            if (!value) {
                errors.mobileCode = "This field is required.";
                this.setState({
                    errors: errors
                })
            } else {
                let errors = this.state.errors;
                delete errors.mobileCode;
                this.setState({
                    errors: errors
                })
            }
        }

        if ((validation == 'all' || validation == 'userInputPostcode') && (!this.props.currentState.postcodeId || this.props.currentState.flow == 'deals')) {

            let errors = this.state.errors;
            if (!this.state.userInputPostcode && validation == 'userInputPostcode') {
                errors.userInputPostcode = "Please enter your suburb or postcode.";
                this.setState({
                    errors: errors
                })
            }
            else if (!this.state.postcodeId && validation == 'all') {
                errors.userInputPostcode = "Please enter valid suburb or postcode.";
                this.setState({
                    errors: errors
                })
            } else {
                delete errors.userInputPostcode;
                this.setState({
                    errors: errors
                })
            }
        }

        let allErrors = this.state.errors;

        if (Object.keys(allErrors).length === 0
            && allErrors.constructor === Object && validation == 'all') {
            var currentStep = this.props.currentState.steps;
            this.submitLead();
            // if (currentStep.current == 'property-info-brokers') {
            //     log('===> you are now here.');
            //     this.props.handleChangeStep('choose-postcode', this.state.formData);
            // }

            // if (currentStep.current == 'property-info-deals') {
            //     this.props.handleChangeStep('deal-listing', this.state.formData);
            // }
        }


    }

    timeSelectionDropdown(starting_hour, starting_minutes) {

        //log('====>' + starting_hour + ' ' + starting_minutes);

        let i, startinghour, mrong_eveng, newOptions = [], startinghournew, mrong_evengnew;
        for (i = starting_hour; i <= 19; i++) {
            if (i > 12) {
                startinghour = i - 12;
                startinghour = '0' + startinghour;
            }
            else if (i < 10) {

                startinghour = '0' + i;
            }
            else {
                startinghour = i;
            }
            if (i >= 12)
                mrong_eveng = ' PM'
            else
                mrong_eveng = ' AM'

            let startingMinutesLatest = parseInt(starting_minutes) + 15;
            let startinghoursLatest = startinghour;
            if (startingMinutesLatest == 60) {
                startingMinutesLatest = '00';
                startinghoursLatest = parseInt(startinghour) + 1;

            }
            if (startinghoursLatest == 13) {
                startinghoursLatest = '01';

            }

            newOptions[startinghour + ':' + starting_minutes + mrong_eveng] = startinghour + ':' + starting_minutes + mrong_eveng + '-' + startinghoursLatest + ':' + startingMinutesLatest + mrong_eveng;
            if (starting_minutes == '00' && i != 20) {
                starting_minutes = '15';
                newOptions[startinghour + ':' + '15' + mrong_eveng] = startinghour + ':' + '15' + mrong_eveng + '-' + startinghour + ':' + '30' + mrong_eveng;

            }
            if (starting_minutes == '15' && i != 20) {
                starting_minutes = '30';
                newOptions[startinghour + ':' + '30' + mrong_eveng] = startinghour + ':' + '30' + mrong_eveng + '-' + startinghour + ':' + '45' + mrong_eveng;

            }

            if (starting_minutes == '30' && i != 20) {
                starting_minutes = '45';
                startinghournew = parseInt(startinghour) + 1;
                /********************/
                if (startinghournew == 13) {
                    startinghournew = 1;

                }
                if (startinghournew < 10) {
                    startinghournew = '0' + startinghournew;
                }
                if (startinghournew == 12) {
                    mrong_evengnew = 'PM'
                }
                else {
                    mrong_evengnew = mrong_eveng;
                }

                /*********************/
                newOptions[startinghour + ':' + '45' + mrong_eveng] = startinghour + ':' + '45' + mrong_eveng + '-' + startinghournew + ':' + '00' + mrong_evengnew;

            }

            if (starting_minutes == '45' && i != 20) {
                starting_minutes = '00';
                //newOptions[startinghour + ':' + '00' + mrong_eveng] = startinghour + ':'+ '00' + mrong_eveng;
            }
        }

        // this.setState({
        //     ar_best_time: allTimes
        // })
        let allTimes = []
        for (let key in newOptions) {
            if (newOptions.hasOwnProperty(key)) {
                let t = {};
                t['key'] = key;
                t['value'] = newOptions[key];
                allTimes.push(t)
                //log(key + " -> " + newOptions[key]);
            }
        }
        this.setState({
            ar_best_time: allTimes
        })

        return allTimes;
    }

    validMobileNum(MobNumber) {

        if (MobNumber.length == 9) {
            if (MobNumber.slice(0, 1) == 4) {
                return true;
            } else {
                return false;
            }
        } else if (MobNumber.length == 10) {
            if (MobNumber.slice(0, 2) == '04') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    isValidDate(dateString) {
        let regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx)) return false;  // Invalid format
        let d = new Date(dateString);
        if (Number.isNaN(d.getTime())) return false; // Invalid date
        return d.toISOString().slice(0, 10) === dateString;
    }

    setBestTime(selected_date) {

        let scope = {};

        scope.currentDate = new Date();
        scope.CurrentYear = scope.currentDate.getFullYear();
        scope.CurrentMonth = scope.currentDate.getMonth() + 1;
        scope.CurrentDate = scope.currentDate.getDate();
        scope.Currenthours = scope.currentDate.getHours();
        scope.Currentmints = scope.currentDate.getMinutes();

        let currentDateTime = new Date();
        selected_date = new Date(selected_date);
        let formatttedCurrentSelectedDate = selected_date.getFullYear() + '/' + (selected_date.getMonth() + 1) + '/' + selected_date.getDate();
        let formatttedCurrentDate = scope.CurrentYear + '/' + scope.CurrentMonth + '/' + scope.CurrentDate;

        let currentD = new Date();
        let startHappyHourD = new Date();
        startHappyHourD.setHours(7, 30, 0); // 7:30 am
        let endHappyHourD = new Date();
        endHappyHourD.setHours(19, 30, 0); // 7.30 pm


        let ctime = scope.Currenthours + ":" + scope.Currentmints;
        /*log(ctime);
        log(formatttedCurrentDate);
        log(formatttedCurrentSelectedDate); */
        let starting_minutes;
        let starting_hour;
        if (formatttedCurrentDate == formatttedCurrentSelectedDate && currentD >= startHappyHourD && currentD < endHappyHourD) {
            currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
            starting_minutes = currentDateTime.getMinutes();
            starting_hour = currentDateTime.getHours();
            //log('hh'+starting_minutes);
            if (starting_minutes < '10')
                starting_minutes = '0' + starting_minutes;
            if (starting_minutes > '00' && starting_minutes <= '15')
                starting_minutes = '15';
            if (starting_minutes > '15' && starting_minutes <= '30')
                starting_minutes = '30';
            if (starting_minutes > '30' && starting_minutes <= '45')
                starting_minutes = '45';
            if (starting_minutes > '45') {
                starting_hour = starting_hour + 1;
                starting_minutes = '00';
            }

        }
        else {
            starting_hour = 8;
            starting_minutes = '00';
        }

        log('ff' + starting_hour + ' ' + starting_minutes);

        return this.timeSelectionDropdown(starting_hour, starting_minutes);
    }


    sendMobileCode(event, type = '') {

        let mobile = this.state.formData.mobile;
        this.validateForm('', 'mobile', mobile);
        if (!!mobile && this.validMobileNum(mobile)) {

            let data = {
                phone: mobile
            }
            document.getElementById("VerifyMobileButton").classList.add('loading');

            this.setState({ submissionErrorMessage: '', mobileCode: '' });
            Axios.post(host + '/send-sms-code-v3', data,
                {
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded"
                    }
                }).then((response) => {
                    log(response);
                    document.getElementById("VerifyMobileButton").classList.remove('loading');
                    if (response.data.status == "100") {
                        this.setState({
                            uuid: response.data.message.id,
                            showCodeVerification: true
                        })
                    } else {

                        let errors = this.state.errors;
                        let errorMessage = '';
                        // if(response.data.status=='blocked')
                        // {
                        //     errorMessage="Due to too many attempts, this number has been blocked for one day.";
                        // }else if(response.data.status=='exists'){
                        //      errorMessage='We already have your details. Please <a href="/consumer-login">click here</a> to login.';
                        // }else if(response.data.status=='bypass'){
                        //         this.setState({
                        //         verifiedNumber: mobile,
                        //         showCodeVerification: false,
                        //         codeVerified: true
                        //     })
                        // }else{
                        //     errorMessage="Something went wrong.";
                        // }
                        // errors.mobile = errorMessage ;
                        // this.setState({
                        //     errors: errors
                        // })
                        if (response.data.status != '100') {
                            errorMessage = response.data.message;
                        }
                        else if (response.data.status == '103') {
                            this.setState({
                                verifiedNumber: mobile,
                                showCodeVerification: false,
                                codeVerified: true
                            })
                        }
                        errors.mobile = errorMessage;
                        this.setState({
                            errors: errors
                        })

                    }
                    log(response);
                })
        }
    }

    verifyMobileCode(event, code = '') {
        log("Im inside mobile code verify");
        log(arguments)
        let uuid = this.state.uuid;
        let mobile = this.state.formData.mobile;
        let mobileCode = this.state.mobileCode;
        this.validateForm('', 'mobileCode', mobileCode);
        if (!!mobileCode && !!mobile && this.validMobileNum(mobile)) {
            Axios.post(host + '/validate-mobile-code-blog', 'mobilecode=' + mobileCode + '&uuid=' + uuid,
                {
                    headers: {
                        "Content-type": "application/x-www-form-urlencoded"
                    }
                }).then((response) => {
                    if (response.data.status == "pass") {
                        this.setState({
                            verifiedNumber: mobile,
                            showCodeVerification: false,
                            codeVerified: true
                        })
                    } else {
                        let errors = this.state.errors;
                        errors.mobileCode = response.data.mobileCode;
                        this.setState({
                            errors: errors
                        })
                    }
                    log(response);
                })
        }
    }

    changeNumber(event) {
        this.setState({
            showCodeVerification: false,
            codeVerified: false,
            formData: { ...this.state.formData, 'mobile': '' },
            mobileCode: '',
            submissionErrorMessage: ''
        })
    }

    componentDidMount() {
        
        window.setTimeout(function(){
            document.getElementById("activeCheck").checked = true;
            
        }, 300);

        let bestDay = moment(this.getSpecificDates()[0]).format('YYYY/MM/DD');

        let arBestTimes = this.setBestTime(bestDay);

        if (typeof window != 'undefined' && typeof window.ga != 'undefined') {
            this.setState({
                gaid: window.ga.getAll()[0].get('clientId')
            })
        }

        document.getElementsByClassName(Styles.slectdate)[0].readOnly = true;

        if (typeof arBestTimes == 'object') {
            this.setState({
                formData: {
                    ...this.state.formData, best_time: arBestTimes[0].value,
                    email: (this.cookieGet('SaveEmail') != undefined) ? this.cookieGet('SaveEmail') : '',
                    first_name: (this.cookieGet('SaveName') != undefined) ? this.cookieGet('SaveName') : ''
                }
            })
        }
    }
    onChangePostcode(event) {
        let userInput = event.currentTarget.value;
        let postcode = [];
        let _this = this;
        let errors = this.state.errors;
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        if (!userInput) {
            this.setState({
                suggestions: [],
                suggestionClass: 'closeSuggestionClass'
            });
        }

        if (userInput.length >= 3) {
            if (parseInt(userInput) != 0 || parseInt(userInput) == 'NaN') {
                const url = host + `/suburblookup?suburblookup=${userInput}`

                if (this.state.requestpostcode) {
                    source.cancel();
                }

                this.state.requestpostcode = Axios.get(url, {
                    cancelToken: source.token
                }).catch(function (error) {
                    if (Axios.isCancel(error)) {
                        log('Request canceled');
                    } else {
                        console.error(error);
                    }
                }).then(function (response) {
                    if (response && userInput) {
                        postcode = response.data.postcodes;
                        if (postcode.length) {
                            _this.setState({
                                suggestions: postcode,
                                suggestionClass: 'openSuggestionClass',
                                requestpostcode: null,
                            });
                        } else {
                            errors.userInputPostcode = "Please enter valid suburb or postcode.";
                            _this.setState({
                                suggestions: postcode,
                                requestpostcode: null,
                                suggestionClass: 'closeSuggestionClass',
                                errors: errors
                            });
                        }
                    } else {
                        console.error('no response');
                    }
                })
            }
        }

        if (userInput.length > 0) {
            _this.setState({
                userInputPostcode: event.currentTarget.value,
            });
        } else {
            _this.setState({
                userInputPostcode: event.currentTarget.value,
                suggestionClass: 'closeSuggestionClass',
            });
        }

    }
    
    onPostcodeSelect(event, id) {

        let _this = this;
        _this.setState({
            userInputPostcode: event.currentTarget.innerText,
            suggestionClass: 'closeSuggestionClass',
            postcodeId: id
        });
        if (!!event.currentTarget.innerText) {
            var postcodes = event.currentTarget.innerText.split(',');
            const cookies = new Cookies();
            postcodes[3] = id;
            cookies.set('postcode_id', id, { path: '/' });
            cookies.set('postcode', postcodes[0], { path: '/' });
            cookies.set('locality', postcodes[1], { path: '/' });
            cookies.set('statecode', postcodes[2], { path: '/' });
            let errors = this.state.errors;
            delete errors.userInputPostcode;
            this.setState({
                postcode: postcodes,
                errors: errors
            })

        }
    }

    cookieSetNew(event, value) {
        if(value==true)
        {
            let cookies = new Cookies();
            cookies.set('consentcookie', 1, { path: '/' });
            return cookies.get('consentcookie')
        }
        else
        {
            let cookies = new Cookies();
            cookies.set('consentcookie', 0, { path: '/' });
            return cookies.get('consentcookie')
        }
        console.log(value);

    }

    handleKeyPress(target) {
        if (target.charCode == 13) {
            // alert('Enter clicked!!!'); 
            document.getElementById('confirmbtn').click();
        }
    }
    onPostcodeKeyDown() {
        let _this = this;
        let errors = this.state.errors;
        errors.userInputPostcode = "Please enter your suburb or postcode.";
        _this.setState({
            suggestions: [],
            suggestionClass: 'closeSuggestionClass',
            errors: errors
        });
    }
    clearPostcodeSelection() {
        removeCookie('postcode_id');
        removeCookie('postcode');
        removeCookie('locality');
        removeCookie('statecode');
        let _this = this;
        let errors = this.state.errors;
        errors.userInputPostcode = "Please enter valid suburb or postcode";
        _this.setState({
            suggestions: [],
            suggestionClass: 'closeSuggestionClass',
            postcode: null,
            postcodeId: '',
            userInputPostcode: '',
            errors: errors
        });

    }
    



    render() {

        let activeClass = '', agreement;
        agreement = this.state.formData.agree;

        if (agreement == true) {
            activeClass = Styles.activeOption;
        }
        
            let  first_name_display = (this.cookieGet('SaveName') != undefined) ? this.cookieGet('SaveName') : '';
            let  email_display = (this.cookieGet('SaveEmail') != undefined) ? this.cookieGet('SaveEmail') : '';
        const suggestionClass = this.state.suggestionClass;
        const showPostCodeSelection = (!this.props.currentState.postcodeId || this.props.currentState.flow == 'deals') ? true : false;
        return (
            <Fragment>
                <StickyBottomBar {...this.props} hideSkip={true}> </StickyBottomBar>
                <section className={cx(Styles.getstartedStepsPage, Styles.pageBgcolor, Styles.stickyBarSpace)}>
                    <FunnerProgressHeader {...this.props} active={'Appointment'} />
                    <section className={cx(Styles.loandealStepSection)}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={cx('col-xl-10 col-lg-12 mr-auto ml-auto', Styles.loandealStepsContnt)}>
                                    <div className={cx(Styles.magnifyImgMain)}>
                                        <img className={cx(Styles.magnifyImg)} src={hashchingMagnifyingGlass} alt="hashchingMagnifyingGlass" />
                                    </div>
                                    <div className={'row'}>
                                        <div className={cx('col-lg-6 mr-auto ml-auto', Styles.loandealStepLeft, Styles.bookAppoinmntLeft)}>
                                            <div className={cx(Styles.loandealStepLeftcontnt)}>
                                            

                                                <div className={cx('card', Styles.card)}>
                                                    <div className={cx('card-body', Styles.cardBody)}>
                                                        <h2 className={cx(Styles.sectionTitle)}>Choose when you <br /> would like to be <br /> contacted</h2>
                                                        <h3 className={cx(Styles.sectionsubtitle)}> FREE Consultation </h3>
                                                        <div className={cx('form-fields', Styles.formFields)}>
                                                            <div className={cx(Styles.bookAppoinmntFields)}>
                                                                <div className={cx('form-group', Styles.formGroup, Styles.slectdateField)}>
                                                                    {/* <DatePicker
                                                                    className={cx('form-control', Styles.slectdate)}
                                                                    placeholderText=""
                                                                    onChange={() => {
                                                                        log('asdf');
                                                                    }}
                                                                /> */}
                                                                    <label>What is the best day to contact you?</label>
                                                                    {/* <input type="text" className={cx('form-control', Styles.slectdate)} placeholder="Thu 20 Dec" onChange={((event) => {
                                                                    this.validateForm(event, 'best_day', event.target.value);
                                                                })} /> */}
                                                                    <div className={cx('form-control', Styles.formControl)} >
                                                                        <DatePicker
                                                                            className={Styles.slectdate}
                                                                            placeholderText="2019/01/31"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            selected={(this.state.formData.best_day) ? new Date(this.state.formData.best_day) : new Date(this.getSpecificDates()[0])}
                                                                            includeDates={this.getSpecificDates()}
                                                                            onChange={((value) => {
                                                                                log('date selected')
                                                                                log(value)
                                                                                this.setState({
                                                                                    formData: { ...this.state.formData, 'best_time': '' }
                                                                                })
                                                                                this.validateForm('', 'best_day', value);
                                                                            })}

                                                                        />
                                                                    </div>
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.best_day) ? 'block' : 'none' }}>
                                                                        {this.state.errors.best_day}
                                                                    </div>
                                                                </div>
                                                                {/* {log(this.state.ar_best_time)} */}
                                                                <div className={cx('form-group', Styles.formGroup)}>
                                                                    {/* {this.state.ar_best_time.map((time) => (
                                                                    <li key={time}>
                                                                        {time}
                                                                    </li>
                                                                ))} */}
                                                                    <label>What is the best time?</label>
                                                                    <select className={cx('form-control', Styles.formControl, Styles.slecttime)} value={this.state.formData.best_time} onChange={((event) => {
                                                                        this.validateForm(event, 'best_time', event.target.value);
                                                                    })}>
                                                                        <option value="">Select Time</option>
                                                                        {this.state.ar_best_time.map((time) => {
                                                                            return (
                                                                                <option key={time.key}>{time.value}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                    {/* <input type="text" className={cx('form-control', Styles.slecttime)} placeholder="16:00" onChange={((event) => {
                                                                    this.validateForm(event, 'best_time', event.target.value);
                                                                })} /> */}
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.best_time) ? 'block' : 'none' }}>
                                                                        {this.state.errors.best_time}
                                                                    </div>
                                                                </div>

                                                                <div className={cx('form-group', Styles.formGroup)}>
                                                                    <label>Your first name</label>
                                                                    <input type="text" value={this.state.formData.first_name} onKeyPress={this.handleKeyPress} className={cx('form-control', Styles.formControl)} placeholder="" onChange={((event) => {
                                                                        this.validateForm(event, 'first_name', event.target.value);
                                                                    })} />
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.first_name) ? 'block' : 'none' }}>
                                                                        {this.state.errors.first_name}
                                                                    </div>
                                                                </div>

                                                                <div className={cx('form-group', Styles.formGroup)}>
                                                                    <label>Your email</label>
                                                                    <input type="text" className={cx('form-control', Styles.formControl)} onKeyPress={this.handleKeyPress} value={this.state.formData.email} placeholder="example@example.com" onChange={((event) => {
                                                                        this.validateForm(event, 'email', event.target.value);
                                                                    })} />
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.email) ? 'block' : 'none' }}>
                                                                        {this.state.errors.email}
                                                                    </div>
                                                                </div>
                                                                {
                                                                    showPostCodeSelection ? (<div className={cx('form-group', Styles.formGroup)}>
                                                                        <label>Your suburb/postcode</label>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)}
                                                                            value={this.state.userInputPostcode}
                                                                            placeholder="Enter your suburb or postcode"
                                                                            onChange={((event) => {
                                                                                this.validateForm(event, 'userInputPostcode', event.target.value);
                                                                                this.onChangePostcode(event)
                                                                            })}
                                                                            onKeyDown={this.onPostcodeKeyDown}
                                                                            onFocus={this.clearPostcodeSelection}
                                                                        />
                                                                        <div className={(Styles.invalidFeedback)}
                                                                            style={{ display: (this.state.errors.userInputPostcode) ? 'block' : 'none' }}>
                                                                            {this.state.errors.userInputPostcode}
                                                                        </div>
                                                                        {/* show postcode listing starts */}
                                                                        <div className={cx(classes.suburbContainer, classes[suggestionClass])}>
                                                                            <div className={classes.autoSuggestCodes}>
                                                                                <div className={classes.arrowup}></div>
                                                                                <ul>
                                                                                    {
                                                                                        this.state.suggestions.map((postcode) =>
                                                                                            <li key={postcode.id} onClick={(event) => { this.onPostcodeSelect(event, postcode.id) }}>
                                                                                                <a href="javascript://"  >{`${postcode.postcode}, ${postcode.locality}, ${postcode.statecode}`} </a>
                                                                                            </li>
                                                                                        )
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        {/* show postcode listing ends */}
                                                                    </div>)
                                                                        : ''
                                                                }



                                                                {/* showCodeVerification */}
                                                                <div className={cx('form-group', Styles.formGroup, Styles.mobfield)}>
                                                                    <label>Your mobile number</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <div className={cx('input-group-prepend', Styles.inputGroupPrepend)}>
                                                                            <div className={cx('input-group-text', Styles.inputGroupText)}>+61</div>
                                                                        </div>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} onKeyPress={this.handleKeyPress} placeholder="" onChange={((event) => {
                                                                            this.validateForm(event, 'mobile', event.target.value);
                                                                        })} value={this.state.formData.mobile} style={{ backgroundColor: (this.state.showCodeVerification || this.state.codeVerified) ? '#ffffff' : '' }} disabled={(this.state.showCodeVerification || this.state.codeVerified) ? "disabled" : ""} />
                                                                        <div id="VerifyMobileButton" className={cx('input-group-append', Styles.inputGroupAppend)}>
                                                                            <button className={cx('btn', Styles.blueBtn, Styles.btn, Styles.verifyMobile)} type="button" onClick={(event) => {
                                                                                this.sendMobileCode(event)
                                                                            }} disabled={(this.state.showCodeVerification || this.state.codeVerified) ? "disabled" : ""} >Verify</button>
                                                                        </div>

                                                                        {this.state.showCodeVerification ?

                                                                            <div className={Styles.codeaction}>
                                                                                A 6-Digit code has been sent to your mobile
                                                                            {<span className={(Styles.codeactionLinks)}> <a href="javascript://" onClick={(event) => { this.sendMobileCode(event) }}>Send Again</a> or <a href="javascript://" onClick={(event) => { this.changeNumber(event) }}>Change Number</a>
                                                                                </span>}
                                                                            </div>
                                                                            :
                                                                            ''
                                                                        }

                                                                    </div>

                                                                    <div className={'message-succes'} style={{ display: (this.state.codeVerified) ? 'block' : 'none' }}>
                                                                        Mobile number is verified.
                                                                    </div>


                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.mobile) ? 'block' : 'none' }} dangerouslySetInnerHTML={{ __html: this.state.errors.mobile }}>

                                                                    </div>
                                                                </div>
                                                                {/* showCodeVerification */}
                                                                <div className={cx('form-group', Styles.formGroup, Styles.veryfymobfield)} style={{ display: (!!this.state.showCodeVerification) ? 'block' : 'none' }}>
                                                                    <label>6-Digit Code</label>
                                                                    <div className={cx('input-group', Styles.inputGroup)}>
                                                                        <input type="text" className={cx('form-control', Styles.formControl)} onKeyPress={this.handleKeyPress} placeholder="" onChange={((event) => {
                                                                            this.validateForm(event, 'mobileCode', event.target.value);
                                                                        })} value={this.state.mobileCode} />


                                                                    </div>


                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.mobileCode) ? 'block' : 'none' }}>
                                                                        {this.state.errors.mobileCode}
                                                                    </div>
                                                                </div>
                                                                {/* special offer checkbox start */}
                                                                <div className={cx('form-group', Styles.formGroup, Styles.agreeField, Styles.speclOffrsCheckbox)}>
                                                                    <input className={cx(Styles.styledCheckbox)} type="checkbox" id="activeCheck" name="activeCheck" value="" 
                                                                    onChange={((event) => {
                                                                        this.cookieSetNew(event, event.target.checked); 
                                                                    })} 
                                                                    />
                                                                    
                                                                    <label for="activeCheck">
                                                                    <i className={cx('fa', Styles.faCheck)}>
                                                                        <FontAwesomeIcon icon={faCheck} />
                                                                    </i>
                                                                    </label>
                                                                        <span className={cx(Styles.readingText)}>
                                                                        <span>I consent to receive special offers and marketing materials from HFG Marketplace.</span>
                                                                            <span className={cx(Styles.othrTxt)}> You can change this anytime from the settings page on your dashboard.</span>
                                                                            <small style={{display:'block', color: '#8b8b8b'}}>(Don't worry, we will never give away or sell your details to 3rd parties.)</small>
                                                                        </span>
                                                                </div>
                                                                {/* <div className={cx('form-group', Styles.formGroup, Styles.agreeField, Styles.speclOffrsField)}>
                                                                    <div className={cx(Styles.customCheckmark)}>
                                                                        <span className={cx(Styles.checkmark)} onChange={((event) => {
                                                                            this.cookieSetNew(event,event.target.checked);
                                                                        })}
                                                                        >
                                                                            <i className={cx('fa', Styles.faCheck)} aria-hidden="true">
                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                            </i>
                                                                        </span>
                                                                        <input type="checkbox" name="" value="checked" />
                                                                        <span className={cx(Styles.readingText)}>
                                                                        <span>I consent to recieve special offers and marketing materials from HFG Marketplace.</span>
                                                                            <span className={cx(Styles.othrTxt)}> You can change this anytime from settings page on your dashboard.</span>
                                                                            <small style={{display:'block', color: '#8b8b8b'}}>(Don't worry, we will never give away or sell your email address to 3rd parties.)</small>
                                                                        </span>
                                                                    </div>
                                                                </div> */}
                                                                {/* special offer checkbox end */}
                                                                <div className={cx('form-group', Styles.formGroup, Styles.agreeField)}>
                                                                    <div className={cx(Styles.customCheckmark)}>
                                                                        <span className={cx(Styles.checkmark, activeClass)} onClick={((event) => {
                                                                            this.validateForm(null, 'agree', 'on');
                                                                        })}>
                                                                            <i className={cx('fa', Styles.faCheck)} aria-hidden="true">
                                                                                <FontAwesomeIcon icon={faCheck} />
                                                                            </i>
                                                                        </span>
                                                                        <input type="checkbox" name="" value="true" />
                                                                        <span className={cx(Styles.readingText)}>
                                                                            I agree to HFG Marketplace's <a href="/privacy" target="_blank">Privacy Policy</a> and <a href="/terms-conditions" target="_blank">Terms of use</a>. Please arrange for a HFG Marketplace partner broker to
                                                                        <nobr> contact me.</nobr>
                                                                        </span>
                                                                        <div className={(Styles.invalidFeedback)} style={{ display: (this.state.errors.agree) ? 'block' : 'none' }}>
                                                                            {this.state.errors.agree}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div id="SubmitLeadButton" className={cx('text-center', Styles.fieldsubmtBtn)}>
                                                                    <div className={(Styles.invalidFeedback)} style={{ display: (this.state.submissionError) ? 'block' : 'none' }}>
                                                                        <span dangerouslySetInnerHTML={{ __html: this.state.submissionErrorMessage }}></span>                                         </div>
                                                                    <a className={cx('btn', Styles.blueBtn, Styles.btn)} id='confirmbtn' style={{ opacity: (!!this.state.showCodeVerification) ? '1' : '0.5' }} href="#" onClick={this.validateForm}>Confirm booking</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={cx('col-lg-5', Styles.loandealStepRight, Styles.ratesSection)}>
                                            <div className={cx(Styles.loandealStepRightcontnt, Styles.ratesSectionContnt)}>
                                                <div className={cx(Styles.ratesdetailPart)}>
                                                    <div className={cx('card', Styles.card)}>
                                                        <div className={cx('card-body', Styles.cardBody)}>
                                                            <div className={cx(Styles.ratesdetailPartinnr)}>
                                                                <h3 className={cx(Styles.sectionTitle)}>Congratulations {first_name_display}!</h3>
                                                                <svg className={cx("float-left", Styles.iconHomeLoan)} width="50" height="50" viewBox="0 0 40 40">
                                                                    <defs>
                                                                        <clipPath id="clip-Custom_Size_1">
                                                                            <rect width="40" height="40" />
                                                                        </clipPath>
                                                                    </defs>
                                                                    <g id="Custom_Size_1" data-name="Custom Size – 1" clip-path="url(#clip-Custom_Size_1)">
                                                                        <g id="_016-house-17" data-name="016-house-17" transform="translate(2 -19.53)">
                                                                            <rect id="Rectangle_51" data-name="Rectangle 51" width="4.14" height="6.179" transform="translate(7.468 24.926)" fill="#ffb125" />
                                                                            <rect id="Rectangle_52" data-name="Rectangle 52" width="2.07" height="6.179" transform="translate(9.538 24.926)" fill="#ff901d" />
                                                                            <path id="Path_2088" data-name="Path 2088" d="M101.962,27.372H96.488a.155.155,0,0,1-.155-.155v-1.57a.155.155,0,0,1,.155-.155h5.474a.155.155,0,0,1,.155.155v1.57A.155.155,0,0,1,101.962,27.372Z" transform="translate(-89.687 -1.826)" fill="#ffb125" />
                                                                            <path id="Path_2089" data-name="Path 2089" d="M91.671,66.739,78.523,57.992,65.374,66.739V87.473h26.3V66.739Z" transform="translate(-60.864 -32.084)" fill="#e1effb" />
                                                                            <path id="Path_2090" data-name="Path 2090" d="M247.7,66.739l-13.149-8.747-1.586,1.055,11.562,7.691h.006V87.473H247.7V66.739Z" transform="translate(-216.891 -32.084)" fill="#ccdfed" />
                                                                            <path id="Path_2091" data-name="Path 2091" d="M2.651,36.936,18.039,26.7a.4.4,0,0,1,.445,0L33.872,36.936a1.292,1.292,0,0,0,1.431,0h0a1.292,1.292,0,0,0,0-2.151L18.484,23.6a.4.4,0,0,0-.445,0L1.22,34.786a1.292,1.292,0,0,0,0,2.151h0A1.291,1.291,0,0,0,2.651,36.936Z" transform="translate(-0.6)" fill="#ff4c4c" />
                                                                            <path id="Path_2092" data-name="Path 2092" d="M34.272,457.979H1.052A1.052,1.052,0,0,0,0,459.031H0a1.052,1.052,0,0,0,1.052,1.052h33.22a1.052,1.052,0,0,0,1.052-1.052h0A1.052,1.052,0,0,0,34.272,457.979Z" transform="translate(0 -404.475)" fill="#2d5872" />
                                                                            <circle id="Ellipse_141" data-name="Ellipse 141" cx="9.197" cy="9.197" r="9.197" transform="translate(8.465 32.297)" fill="#21d8de" />
                                                                            <path id="Path_2093" data-name="Path 2093" d="M233.873,150.6a9.253,9.253,0,0,0-1.64.146,9.2,9.2,0,0,1,0,18.1,9.2,9.2,0,1,0,1.64-18.247Z" transform="translate(-216.211 -118.307)" fill="#11b9c1" />
                                                                            <path id="Path_2095" data-name="Path 2095" d="M35.318,458.934c0-.007,0-.014,0-.021,0-.03-.008-.059-.013-.087L35.3,458.8q-.008-.035-.018-.068l-.008-.026c-.008-.024-.016-.047-.026-.07l-.014-.033c-.01-.022-.02-.044-.032-.066a1.051,1.051,0,0,0-.93-.56H32.509v.139a.981.981,0,0,1-.981.981H1.051a1.051,1.051,0,0,1-.93-.56,1.047,1.047,0,0,0-.121.538,1.071,1.071,0,0,0,1.08,1.006H34.271a1.052,1.052,0,0,0,1.052-1.052C35.323,459,35.321,458.966,35.318,458.934Z" transform="translate(0 -404.474)" fill="#1d4859" />
                                                                            <path id="Path_2096" data-name="Path 2096" d="M35.9,57.2s0-.006,0-.009h0c0-.033,0-.066-.009-.1l0-.02c-.005-.037-.012-.074-.02-.111l0-.014c-.009-.038-.019-.076-.032-.113h0c-.012-.037-.027-.074-.043-.109l-.007-.016c-.012-.027-.026-.054-.04-.081h0l-.012-.02-.009-.016c-.02-.034-.042-.067-.065-.1v0h0a1.289,1.289,0,0,0-.33-.317l-1.559-1.037-.1.144a1.374,1.374,0,0,1-1.148.614L18.5,46.567a.4.4,0,0,0-.445,0L2.671,56.8a1.292,1.292,0,0,1-1.431,0h0a1.292,1.292,0,0,1-.331-.316,1.274,1.274,0,0,0-.087.139h0c-.01.017-.017.036-.026.053s-.014.028-.021.043-.021.051-.03.077-.01.026-.015.039c-.011.033-.02.067-.029.1,0,.006,0,.011,0,.017-.009.04-.016.08-.021.12,0,.013,0,.026,0,.039s-.006.055-.007.083,0,.032,0,.049,0,.049,0,.073,0,.035,0,.052,0,.046.008.069.006.035.01.053.009.045.014.067.009.035.015.053.013.044.021.065.013.034.02.052.018.042.028.063.016.033.024.05.022.041.034.061.019.032.029.047.028.04.042.059.021.029.032.043.034.039.051.058.023.026.035.038.042.04.065.06l.033.029a1.334,1.334,0,0,0,.109.081h0a1.292,1.292,0,0,0,1.431,0L18.059,48.084a.4.4,0,0,1,.445,0l13.831,9.2,1.557,1.036a1.292,1.292,0,0,0,1.431,0c.033-.022.063-.045.093-.069l.008-.007.04-.035c.019-.017.038-.033.056-.051l0,0,0,0c.012-.012.023-.026.035-.038l.032-.035.016-.019,0,0c.011-.014.022-.029.033-.044s.02-.026.029-.039l0-.008.008-.013c.01-.015.019-.031.029-.047l.016-.027.01-.019.008-.016c.009-.016.016-.033.024-.05s.011-.021.015-.031l0-.008.009-.025c.007-.017.013-.034.019-.052l.008-.02,0-.015c0-.01.005-.02.008-.031s.01-.035.015-.053,0-.015.006-.022v-.005c0-.013,0-.026.007-.039s.007-.035.01-.053l0-.014v-.008c0-.016,0-.032,0-.047s0-.035,0-.052,0-.009,0-.014a.02.02,0,0,1,0,0c0-.019,0-.039,0-.058S35.9,57.213,35.9,57.2Z" transform="translate(-0.62 -21.384)" fill="#f33939" />
                                                                            <path id="Path_2097" data-name="Path 2097" d="M3,.146A4.23,4.23,0,0,1,.984-.514l.609-1.479a3.349,3.349,0,0,0,1.923.686q1.454,0,1.454-1A1.44,1.44,0,0,0,4.6-3.253,4.59,4.59,0,0,0,3.19-4.215a6.8,6.8,0,0,1-1.412-.863A2.307,2.307,0,0,1,1.2-5.9,2.712,2.712,0,0,1,1-6.957,2.329,2.329,0,0,1,1.546-8.48,2.627,2.627,0,0,1,3-9.363v-1.149H4.456L4.45-9.426a3.288,3.288,0,0,1,1.739.571l-.5,1.422a3.1,3.1,0,0,0-1.834-.622,1.212,1.212,0,0,0-.885.308,1.043,1.043,0,0,0-.314.778q0,.8,1.771,1.663a5.778,5.778,0,0,1,1.346.841,2.585,2.585,0,0,1,.628.9,2.9,2.9,0,0,1,.216,1.139A2.3,2.3,0,0,1,6.043-.879,2.944,2.944,0,0,1,4.456.032v1.46H3Z" transform="translate(14 45.53)" fill="#fff" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                <p class={Styles.rateBody}>You might be eligible for our lowest home loan rates!</p>
                                                                <div className={cx(Styles.rateHeader)}>
                                                                    <small>HOME LOAN STARTS FROM</small>
                                                                </div>
                                                                <div className={cx("text-center d-flex justify-content-center flex-row", Styles.priceTags)}>
                                                                    <div className={cx("p-2", Styles.priceColumns)}>
                                                                        <span>{cookies.get('rates_interest_rate')}<sup>%</sup></span><small>p.a.</small><br /><small>BASE RATE</small></div>
                                                                    <div className={cx("p-2", Styles.priceColumns)}>
                                                                        <span>{cookies.get('rates_comparison_interest_rate')}<sup>%</sup></span><small>p.a.</small><br /><small>COMPARISON RATE</small></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={cx('card', Styles.card)}>
                                                        <div className={cx('card-body', Styles.cardBody)}>
                                                            <h3 className={cx(Styles.sectionTitle)}>Why do we have better rates?</h3>
                                                            <p className={cx(Styles.sectionSummry)}>We have 2000+ brokers in our network. They know the ins and out of the market and can get the best deals out-there. <strong>They negotiate for you and will get you unbeatable rates.</strong></p>
                                                            <div className={cx(Styles.paneloflenders)}>
                                                                <h3 className={cx(Styles.sectionTitle)}>Panel of Lenders</h3>

                                                                <div className={Styles.lendrsImgs}>
                                                                    <div className={cx(Styles.lendrimg, Styles.lendrscommbank)}></div>
                                                                    <div className={cx(Styles.lendrimg, Styles.lendrsing)}> </div>
                                                                    <div className={cx(Styles.lendrimg, Styles.lendrsnab)}></div>
                                                                    {/*  <div className={cx(Styles.lendrimg, Styles.lendrsmacquarie)}> </div> */}
                                                                    <div className={cx(Styles.lendrimg, Styles.lendrsnewcastle)}></div>
                                                                    <div className={cx(Styles.lendrimg, Styles.lendrscitibank)}></div>
                                                                    <div className={cx(Styles.lendrimg, Styles.lendrsanz)}></div>
                                                                    <div className={cx(Styles.lendrimg, Styles.lendrsvirgin)}></div>
                                                                    <div className={cx(Styles.lendrimg, Styles.lendrsauswide)}></div>
                                                                    {/* <div className={cx(Styles.lendrimg, Styles.lendrswestpac)}> </div> */}
                                                                </div>
                                                                <p className={Styles.plusmoreText}>And 100+ more…</p>
                                                            </div>
                                                            {/* <h3 className={cx(Styles.sectionTitle)}>Why we don’t show the lender</h3>
                        <p className={cx(Styles.sectionSummry)}>Our highly experienced partner mortgage brokers negotiate a better deal from lenders directly and lenders don&apos;t want their deals to be advertised openly. Speak to our broker and they will disclose the lender to you. Don&apos;t worry, our partner brokers have plenty more deals from other lenders and they will source a better deal most suited to your needs at no cost to you.</p>
                     */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </section>
            </Fragment>
        )
    }
}

const ThankYou = (props) => {

    log(props.funnelData.bookAppointment);

    let date = props.funnelData.bookAppointment.best_day;

    let time = props.funnelData.bookAppointment.best_time.split("-")[0];

    let formatedDate = moment(date + ' ' + time).format("dddd Do MMMM");

    // log(moment.utc(date + ' ' + time))

    let timeFrom = moment(date + ' ' + time).utc().format("YYYYMMDDTHHmm00") + 'Z';

    let timeTo = moment(date + ' ' + props.funnelData.bookAppointment.best_time.split("-")[1]).utc().format("YYYYMMDDTHHmm00") + 'Z';

    log(timeFrom, "===> time from");

    log(timeTo, "===> time to");

    let redirectUrl = '/thanks-home-loan-enquiry-dashboard-redirect?token=' + props.funnelData.bookAppointment.ref;

    if (!!props.currentState.utmTags.referrerId) {
        redirectUrl = redirectUrl + '&key=' + props.currentState.utmTags.referrerId
    }



    let addToCalenderLink = 'https://calendar.google.com/calendar/r/eventedit?text=Broker+Appointment&dates=' + timeFrom + '/' + timeTo + '&sf=true&output=xml'

    return (
        <Fragment>
            <section className={cx(Styles.loandealStepSection)}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={cx('col-xl-5 col-lg-6 col-md-7 col-sm-12', 'mr-auto', 'ml-auto', Styles.othrstepSection)}>

                            <div className={cx(Styles.thnkuSection, 'text-center')}>
                                <div className={cx(Styles.sectioncontnt)}>
                                    <img src={tickImage} alt="tick-img" />
                                    <h3 className={cx('text-center', Styles.sectionHeding)}>Thank you</h3>
                                    <p className={cx(Styles.dscrption)}>You are confirmed for <br /><strong>{time} </strong>on <strong>{formatedDate}</strong></p>
                                    <a className={cx(Styles.addCalndrLink)} href={addToCalenderLink} target="_blank">Add to Calendar</a>
                                    <div className={cx('text-center', Styles.fieldsubmtBtn)}>
                                        <a className={cx('btn', Styles.blueBtn, Styles.continuBtn)} href="javascript:void(0);" onClick={() => {
                                            props.handleChangeStep('dashboard');
                                        }}>Continue to Dashboard</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export class WelComeToDashboard extends React.Component {

    render() {

        log(this.props.funnelData.bookAppointment);

        let ref = this.props.funnelData.bookAppointment.ref;

        log("funnel cookie removed..");

        removeCookie('funnelData');

        return (
            <Fragment>
                <section className={cx(Styles.loandealStepSection)}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={cx('col-xl-8 col-lg-12', 'mr-auto', 'ml-auto', Styles.dashbordStepSection)}>
                                <div className={cx('text-center', Styles.headrLogo)}>
                                    <a href={'/'}>
                                        <img className={cx(Styles.logoHashching)} src={logo} alt="HFG Marketplace home loans" />
                                    </a>
                                </div>
                                <h3 className={cx('text-center', Styles.sectionTitle)}>Welcome to your dashboard. </h3>
                                <h4 className={cx('text-center', Styles.sectionSubTitle)}>
                                    Follow up your application and communications in this <nobr>central place.</nobr></h4>
                                <div className={cx(Styles.dashbordStepContnt)}>
                                    <div className={cx('row')}>
                                        <div className={cx('col-md-6', Styles.sectionLeft)}>
                                            <figure className={cx(Styles.figure)}>
                                                <img className={cx(Styles.sectionimg, Styles.dsktopimg)} src={SecuredappDesktop} alt="securedapp" />
                                                <img className={cx(Styles.sectionimg, Styles.mobimg)} src={SecuredappMobile} alt="securedapp" />

                                            </figure>
                                        </div>
                                        <div className={cx('col-md-6', Styles.sectionRight)}>
                                            <div className={cx(Styles.sectionRightInnr)}>
                                                <ul className={cx(Styles.stepsListng)}>
                                                    <li>
                                                        <span className={cx(Styles.stepListingIcon)}><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                                            <g fill="#1E7B97" fillRule="evenodd">
                                                                <path d="M8.278 6.386l2.25 6.366 2.833-9.264 2.635 7.465h5.864l.001-.057c0-5.987-4.853-10.84-10.84-10.84C5.038.056.185 4.91.185 10.895v.057h5.93l2.164-4.566z" />
                                                                <path d="M13.478 9.04l-2.832 9.262-2.602-7.365-.831 1.753H.333c.856 5.13 5.314 9.043 10.688 9.043 5.375 0 9.833-3.913 10.688-9.044h-6.943L13.478 9.04z" />
                                                            </g>
                                                        </svg></span>
                                                        <span className={cx(Styles.stepListingTxt)}>Keep track of your application</span>
                                                    </li>
                                                    <li>
                                                        <span className={cx(Styles.stepListingIcon)}><svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
                                                            <path fill="#1E7B97" fillRule="evenodd" d="M14.04 7.77V5.787c0-.514-.076-1.847-.782-3.12C12.583 1.45 11.171 0 8.273 0S3.962 1.451 3.288 2.668c-.706 1.273-.783 2.606-.783 3.12v1.981H1.196C.536 7.77 0 8.305 0 8.965v9.519c0 .66.536 1.196 1.196 1.196h14.575c.66 0 1.197-.535 1.197-1.196v-9.52c0-.66-.536-1.195-1.197-1.195h-1.73zM8.275 2.314c3.115 0 3.453 2.43 3.453 3.473v1.981H4.822V5.788c-.001-1.044.335-3.473 3.452-3.473zM6.612 17.4l-2.463-2.774 1.404-1.236 1.081 1.226 4.808-4.568 1.378 1.61L6.612 17.4z" />
                                                        </svg></span>
                                                        <span className={cx(Styles.stepListingTxt)}>Upload your documents securely</span>
                                                    </li>
                                                    <li>
                                                        <span className={cx(Styles.stepListingIcon)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25 19">
                                                            <g fill="#1E7B97" fillRule="evenodd">
                                                                <path d="M22.329 10.773c1.138-1.138 1.82-2.706 1.82-4.451C24.15 2.832 21.318 0 17.802 0c-1.265 0-2.428.354-3.414 1.011a9.494 9.494 0 0 1 3.64 7.51c0 1.492-.328 2.883-.96 4.123.227.025.48.05.708.05.582 0 1.138-.1 1.694-.227.835.582 1.871 1.214 2.958 1.644.658.252 1.265-.456.886-1.038-.505-.834-.783-1.643-.985-2.3z" />
                                                                <path d="M8.42.203A8.299 8.299 0 0 0 .127 8.497c0 2.276.911 4.325 2.403 5.816a11.352 11.352 0 0 1-1.315 2.959c-.506.784.303 1.72 1.138 1.365 1.416-.556 2.782-1.391 3.894-2.15a7.96 7.96 0 0 0 2.2.303 8.299 8.299 0 0 0 8.294-8.294c0-4.577-3.717-8.294-8.32-8.294zM4.806 7.409c.606 0 1.087.48 1.087 1.088a1.08 1.08 0 0 1-1.087 1.088 1.08 1.08 0 0 1-1.088-1.088 1.08 1.08 0 0 1 1.088-1.088zM8.42 9.584a1.08 1.08 0 0 1-1.088-1.088c0-.606.48-1.088 1.088-1.088.606 0 1.088.48 1.088 1.088A1.08 1.08 0 0 1 8.42 9.584zm3.64 0a1.08 1.08 0 0 1-1.087-1.088c0-.606.48-1.088 1.088-1.088.606 0 1.087.48 1.087 1.088 0 .608-.48 1.088-1.087 1.088z" />
                                                            </g>
                                                        </svg></span>
                                                        <span className={cx(Styles.stepListingTxt)}>Instantly message your broker or our support team</span>
                                                    </li>
                                                    <li>
                                                        <span className={cx(Styles.stepListingIcon)}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                                            <g fill="none" fillRule="evenodd">
                                                                <path fill="#1E7B97" d="M10.414.577a.796.796 0 0 0-.479-.162.915.915 0 0 0-.479.162L.43 7.681c-.254.251-.432.794-.17 1.128.26.334.81.38 1.128.097l.756-.572V16.778c0 .43.349.78.78.78h14.025a.78.78 0 0 0 .78-.78V8.333l.737.57c.317.282.878.246 1.128-.097.219-.301.165-.877-.17-1.128l-9.009-7.1z" />
                                                                <path fill="#FFF" d="M9.87 6.753c.693 0 1.266.493 1.299 1.103v.715H8.57v-.715c.033-.61.606-1.103 1.3-1.103m-.513 4.11c.039-.24.22-.438.45-.47.314-.04.582.23.582.564a.55.55 0 0 1-.249.479v.74c0 .166-.124.292-.267.292-.153 0-.267-.136-.267-.292v-.74c-.182-.104-.287-.323-.249-.573m2.612-2.049v-.71c0-1.17-.934-2.13-2.095-2.13-1.15 0-2.095.95-2.095 2.13v.71c-.565.01-1.027.48-1.027 1.055v3.362c0 .585.462 1.055 1.037 1.055h4.16c.575 0 1.037-.47 1.037-1.055V9.87c0-.574-.452-1.044-1.017-1.055" />
                                                            </g>
                                                        </svg></span>
                                                        <span className={cx(Styles.stepListingTxt)}>Get discounted conveyancing and home insurance </span>
                                                    </li>

                                                </ul>

                                            </div>

                                        </div>
                                        <div className={cx('text-center col-sm-12', Styles.fieldsubmtBtn)}>
                                            <a className={cx('btn', Styles.blueBtn, Styles.continuebutton)} href={'/thanks-home-loan-enquiry/' + ref}>Continue</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Fragment>
        )
    }
}

class SaveYourProgress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <Fragment>
                <FunnelSaveYourProgress {...this.props} />
            </Fragment>
        )
    }
}

const Calculator = (props) => {
    return (
        <Fragment>
            <StickyBottomBar {...props} hideSkip={true}> </StickyBottomBar>
            <section className={Styles.stickyBarSpace}>
                <BorrowingCalculator {...props} />
            </section>
        </Fragment>
    )
}

const FunnelDeals = (props) => {

    let cookies = new Cookies();

    let broker = cookies.get('broker');

    let btnText = 'Skip and Continue';

    if (broker != undefined) {
        btnText = 'Skip and Continue'
    }


    return (
        <Fragment>
            <StickyBottomBar {...props} skipBtnText={btnText} skipFrom={'deal-listing'}></StickyBottomBar>
            <div className={cx(Styles.funnelDealListingsection, Styles.stickyBarSpace)}>
                <FunnerProgressHeader {...props} active={'Your rates'} />
                {/* <h2 className={cx('text-center', Styles.funnlDealListTitle)}>Based on your <br/> answers here are <br/>deals you can unlock</h2> */}
                <div className={Styles.funnelDealListing}>
                    <DealList onDealClickHandler={props.dealClickHandler} {...props} dealSkipClickHandler={props.dealSkipClickHandler} />
                </div>
            </div>
        </Fragment>
    )
}

const FunnelDealDetails = (props) => {

    let cookies = new Cookies();

    let broker = cookies.get('broker');

    let btnText = 'Skip and Continue';

    if (broker != undefined) {
        btnText = 'Skip and Continue'
    }

    return (
        <Fragment>
            <StickyBottomBar {...props} skipBtnText={btnText} skipFrom={'deal-details'}></StickyBottomBar>
            <section className={Styles.stickyBarSpace}>
                <FunnerProgressHeader {...props} active={'Your rates'} visible={'hidden'} />
                <DealDetailSection dealID={props.dealID} {...props} />
            </section>
        </Fragment>
    )
}

class FunnelBrokers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            postcode: props.funnelData.postcode,
            suburb: props.funnelData.suburb,
            state: props.funnelData.state,
            searchArea: props.currentState.brokerSearchArea
        };
    }


    render() {

        log(this.state.postcode)

        let location = this.state.suburb + ', ' + this.state.postcode

        if (!!this.state.searchArea) {
            location = this.state.searchArea;
        }

        return (
            <Fragment>
                <StickyBottomBar {...this.props} skipBtnText={'Skip & choose broker for me'} skipFrom={'mortgage-broker-finder'}></StickyBottomBar>
                <div className={cx(Styles.funnelbrokrListingsection, Styles.stickyBarSpace)}>
                    <FunnerProgressHeader {...this.props} active={'Your broker'} />
                    <h2 className={cx('text-center', Styles.brokrResultTitle)}>These are the top <br />local brokers in <span>{location}.</span></h2>

                    <div className={Styles.funnelbrokrListing}>
                        <BrokerListing searchArea={this.state.searchArea} postcode={this.state.postcode} suburb={this.state.suburb} state={this.state.state} postcodeUpdateHandler={this.props.postcodeUpdateHandler} handleBrokerClick={this.props.handleBrokerClick} />
                    </div>

                </div>
            </Fragment>
        );
    }
}

const FunnelBrokerDetails = (props) => {
    return (
        <Fragment>
            <StickyBottomBar {...props} skipBtnText={'Skip & choose broker for me'} skipFrom={'broker-profile'}></StickyBottomBar>
            <div className={cx(Styles.funnelbrokrDetailsection, Styles.pageBgcolor, Styles.stickyBarSpace)}>
                {/* <FunnerProgressHeader {...props} active={'Your broker'} visible={'hidden'} /> */}
                <div className={Styles.funnelbrokrDetailMain}>
                    <BrokerDetailSection brokerId={props.brokerId} {...props}></BrokerDetailSection>
                </div>

            </div>
        </Fragment>
    )
}

const FunnelSaveProgress = (props) => {

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col-md-12'}>

                    <div style={{ margin: '20px auto', width: '300px', padding: '10px' }}>
                        <form>
                            <div className={"form-group"}>
                                <label>Enter your email</label>
                                <input className={"form-control"} defaultValue={props.email} id="progress-email" onChange={props.handleClick} />
                                <div id="progress-email-error" className={"error"}></div>
                            </div>
                            <div className={"form-group"} >
                                <a className={"btn btn-primary"} onClick={props.handleClick}>Save Progress</a>
                            </div>
                            <div className={"form-group"}>
                                <a id="hash-btn-skip-progress" className={"btn"} onClick={props.handleClick}>Skip</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

class FunnelChooseBroker extends React.Component {
    constructor(props) {
        super(props);

        this.myRefChooseBroker = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, this.myRefChooseBroker.current.offsetTop)
        
    }

    render() {
        let cookies = new Cookies()

        log("===> postcode check")

        log(cookies.get('postcode', { 'path': '/' }) == undefined && cookies.get('pCode', { 'path': '/' }) == undefined)

        let hideSkipButton = false;

        if (cookies.get('postcode', { 'path': '/' }) == undefined && cookies.get('pCode', { 'path': '/' }) == undefined) {
            hideSkipButton = true;
        }

        return (
            <Fragment >
                <StickyBottomBar {...this.props} skipBtnText={'Skip & choose a broker for me'} skipFrom={'choose-postcode'} hideSkip={true}></StickyBottomBar>
                <section className={Styles.stickyBarSpace} ref={this.myRefChooseBroker}>
                    <FunnerProgressHeader {...this.props} active={'Your broker'} />
                    <FunnelChoosePostcode {...this.props} />

                </section>
            </Fragment>
        )
    }
}

class FunnelChoosePostcode extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            userInput: "",
            suggestionClass: 'closeSuggestionClass',
            message: 'Please select postcode from list',
            requestpostcode: '',
            flag: false,
            errorMsgClass: 'errorMsgHide',
            postcodeId: '',
            placeholder: "Enter your suburb or postcode",
            postcode: null,
            hideSection: false,
            showSingleBroker: false
        };
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        // this.onClickSearch = this.onClickSearch.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.hideSection = this.hideSection.bind(this);
    }

    componentDidMount() {


        log(this.props.currentState, "In choose postcode current state..");

        if (this.props.currentState.flow == 'deals' && !!this.props.currentState.broker) {
            this.setState({
                hideSection: true,
                showSingleBroker: true
            })
        }

        // if (this.props.currentState.flow == "brokers") {
        //     this.setState({
        //         hideSection: true
        //     })
        // }
    }

    onChange(event) {
        let userInput = event.currentTarget.value;
        let postcode = [];
        let _this = this;
        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();
        this.state.flag = false;
        log(userInput);
        if (!userInput) {
            log('empty');
            this.setState({
                suggestions: [],
                suggestionClass: 'closeSuggestionClass'
            });
        }

        if (userInput.length >= 3) {
            if (parseInt(userInput) != 0 || parseInt(userInput) == 'NaN') {
                const url = host + `/suburblookup?suburblookup=${userInput}`

                if (this.state.requestpostcode) {
                    source.cancel();
                }

                this.state.requestpostcode = Axios.get(url, {
                    cancelToken: source.token
                }).catch(function (error) {
                    if (Axios.isCancel(error)) {
                        log('Request canceled');
                    } else {
                        console.error(error);
                    }
                }).then(function (response) {
                    if (response && userInput) {
                        postcode = response.data.postcodes;
                        if (postcode.length) {
                            _this.setState({
                                suggestions: postcode,
                                suggestionClass: 'openSuggestionClass',
                                message: 'Please select postcode from list',
                                requestpostcode: null,
                            });
                        } else {

                            _this.setState({
                                suggestions: postcode,
                                message: 'Please enter a valid postcode',
                                requestpostcode: null,
                                suggestionClass: 'openSuggestionClass',
                            });
                        }
                    } else {
                        console.error('no response');
                    }
                })
            }
        }

        if (userInput.length > 0) {
            _this.setState({
                userInput: event.currentTarget.value,
                errorMsgClass: 'errorMsgHide'
            });
        } else {
            _this.setState({
                userInput: event.currentTarget.value,
                suggestionClass: 'closeSuggestionClass',
                errorMsgClass: 'errorMsgHide'
            });
        }

    }

    onClick(event, id) {

        let _this = this;
        _this.setState({
            userInput: event.currentTarget.innerText,
            suggestionClass: 'closeSuggestionClass',
            postcodeId: id,
            flag: true
        });
        if (!!event.currentTarget.innerText) {
            var postcodes = event.currentTarget.innerText.split(',');
            const cookies = new Cookies();
            postcodes[3] = id;
            cookies.set('postcode_id', id, { path: '/' });
            cookies.set('postcode', postcodes[0], { path: '/' });
            cookies.set('locality', postcodes[1], { path: '/' });
            cookies.set('statecode', postcodes[2], { path: '/' });

            this.setState({
                postcode: postcodes,
                showSingleBroker: true
            })

            log(this.props, "postcode is clicked");

            if (this.props.currentState.flow == 'brokers') {
                this.props.handleChangeStep('mortgage-broker-finder', postcodes);
            }
        }
    }


    onKeyDown() {
        let _this = this;
        _this.setState({
            suggestions: [],
            message: 'Please enter a valid postcode.',
            suggestionClass: 'closeSuggestionClass',
        });
    }

    hideSection() {
        this.setState({
            hideSection: true
        })
    }

    render() {

        const suggestionClass = this.state.suggestionClass;
        const suggestionMessage = this.state.message;
        const errorMessage = this.state.errorMsgClass;

        return (
            <Fragment>
                <section className={cx("toplocl-brokr-section", "text-center")} >
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-sm-12 toplocl-brokr-contnt"}>
                                <h2 className={"section-title"}>Where do you <br />currently live?</h2>
                                <div className={"selectsuburb"}>
                                    <input type="text" id="" name="" placeholder={this.state.placeholder} onFocus={() => this.setState({ placeholder: '' })} className={"selectsuburb-field"} onChange={this.onChange} onKeyDown={this.onKeyDown} value={this.state.userInput} />
                                    <p className={cx(classes.errorMsg, classes[errorMessage])}>{suggestionMessage}</p>
                                    <div className={cx(classes.suburbContainer, classes[suggestionClass])}>
                                        <div className={classes.autoSuggestCodes}>
                                            <div className={classes.arrowup}></div>
                                            {suggestionMessage == 'Please enter a valid postcode' ? <p className={classes.selectPostcodeHeding}>
                                                {suggestionMessage}
                                            </p> : ''}

                                            <ul>
                                                {
                                                    this.state.suggestions.map((postcode) =>
                                                        <li key={postcode.id} onClick={(event) => { this.onClick(event, postcode.id) }}>
                                                            <a href="javascript://"  >{`${postcode.postcode}, ${postcode.locality}, ${postcode.statecode}`} </a>
                                                        </li>
                                                    )
                                                }
                                            </ul>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            {this.props.currentState.flow == 'deals' && (this.state.showSingleBroker) && <DisplaySingleBroker {...this.props} postcode={this.state.userInput} arPostcode={this.state.postcode} hideSection={this.hideSection} isHidden={this.state.hideSection} />}
                            {!this.state.hideSection && <div className={cx('col-sm-12', Styles.chooseBrokrinfo)}>
                                <div className={Styles.chooseBrokrimg}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="65" viewBox="0 0 59 65">
                                        <g fill="none" fillRule="evenodd">
                                            <path fill="#333" d="M50.342 51.464L45.77 55.3l-.164-.195a2.984 2.984 0 0 1 4.572-3.837l.164.196zm.625.744l6.62 7.889a2.984 2.984 0 0 1-4.573 3.836l-6.62-7.888 4.573-3.837z" />
                                            <path fill="#0AB5DD" d="M27.734 36.964c6.601 0 12.893 2.797 17.315 7.698l.995 1.102c-6.031 5.36-10.048 8.04-12.05 8.04-1.693 0-.699 1.712-12.737 0-1.465-.21-5.426-2.889-11.884-8.04l1.021-1.125a23.424 23.424 0 0 1 17.34-7.675zM20.096 23.529c-.763-1.211-1.21-4.002-1.103-5.632.108-1.63.594-2.898 1.714-3.92-.395-.532-.917-.745-1.12-.746 1.12-2.792 10.318-8.288 14.352-.182.815-.22 1.078-.183 1.212 0 .133.182 1.512 1.221 1.258 8.29-.445 1.02-1.025 1.303-1.054 2.56-.03 1.257-.308 4.388-.739 6.018-.43 1.63-4.009 5.266-5.784 5.266-1.775 0-2.429.202-3.704-.65-1.275-.853-3.347-2.122-4.145-4.138-.798-2.015-.665-4.57-.887-6.866z" />
                                            <path fill="#333" stroke="#FFF" d="M27.817 56.241c-14.748 0-26.704-12.328-26.704-27.537 0-15.208 11.956-27.537 26.704-27.537 14.748 0 26.704 12.33 26.704 27.537 0 15.209-11.956 27.537-26.704 27.537zm0-4.546c12.313 0 22.295-10.293 22.295-22.99 0-12.698-9.982-22.992-22.295-22.992-12.313 0-22.295 10.294-22.295 22.991 0 12.698 9.982 22.991 22.295 22.991z" />
                                        </g>
                                    </svg>
                                </div>
                                <p className={Styles.chooseBrokrTxt}>Over 2000+ mortgage brokers have partnered with HFG Marketplace. By providing your <br />location we can quickly find you top-rated brokers <nobr>closest to you.</nobr></p>
                            </div>}
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}

// export const pageQuery = graphql`
//   query {
//     allKenticoCloudItemFieldMinimumLoanAmount {
//       edges {
//         node {
//           elements {
//             label_minimumloanamount {
//               value
//             }
//           }
//         }
//       }
//     }
//     allKenticoCloudItemLeadSCount {
//       edges {
//         node {
//           elements {
//             label_leads_count {
//               value
//             }
//           }
//         }
//       }
//     }
//   }
// `;
export default FunnelApp